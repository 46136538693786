// src/pages/AddEvent.js
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../utils/routes";
import Calendar from "react-calendar";
import { deductUserCredits, addEvent } from "../../utils/api";
import { useForm } from "../../hooks/useForm";
import { useSelector } from "react-redux";
import "../Common/Calendar.css";
import "./AddEvent.css";

const MESSAGE_TIMEOUT = 5000;

const AddEvent = () => {
    const navigate = useNavigate();
    const topRef = useRef(null);
    const userId = useSelector((state) => state.user?.id);
    const { formData, handleChange, resetForm } = useForm({
        eventName: "",
        eventDate: new Date(),
        cust1: "",
        cust2: "",
        cust3: "",
        cust4: "",
    });

    const [message, setMessage] = useState({ type: "", text: "" });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const tileClassName = ({ date, view }) => {
        if (view === "month") {
            return ""; // Ensure no custom logic is breaking display
        }
    };

    const handleDateChange = (date) => {
        console.log("Selected date:", date);
        const selectedDate = new Date(date);
        console.log("Selected date object:", selectedDate);
        const currentDate = new Date();

        // Reset the time portion of both dates to ensure the comparison is only based on the date
        selectedDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        // Check if the selected date is older than the current date
        if (selectedDate < currentDate) {
            setMessage({
                type: "error",
                text: "The selected date is in the past. Please choose a future date.",
            });
        } else {
            setMessage({ type: "", text: "" }); // Clear any previous message if the date is valid
            handleChange({ target: { name: "eventDate", value: date } });
        }
        console.log(message);
    };

    const clearMessageAfterDelay = () => {
        const timer = setTimeout(
            () => setMessage({ type: "", text: "" }),
            MESSAGE_TIMEOUT
        );
        return () => clearTimeout(timer);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        topRef.current.scrollIntoView({ behavior: "smooth" });

        const eventData = {
            event_name: formData.eventName,
            event_date: formData.eventDate
                ? formData.eventDate.toLocaleDateString("en-ZA")
                : "",
            cust1: formData.cust1 || "cust1",
            cust2: formData.cust2 || "cust2",
            cust3: formData.cust3 || "cust3",
            cust4: formData.cust4 || "cust4",
            user_id: userId,
            type: 2,
        };

        if (!eventData.event_name || !eventData.event_date) {
            setMessage({
                type: "error",
                text: "Event Name and Date are required.",
            });
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await addEvent(eventData);

            if (response.status === "success") {
                await deductUserCredits(userId);

                setMessage({
                    type: "success",
                    text: "Event created successfully!",
                });
                setTimeout(() => navigate(routePaths.dashboard), 2000);
                resetForm();
            } else {
                setMessage({
                    type: "error",
                    text: response.message || "Failed to create event.",
                });
            }
        } catch (error) {
            console.error("Error creating event:", error);
            setMessage({ type: "error", text: "Error creating event." });
        } finally {
            setIsSubmitting(false);
        }
    };

    React.useEffect(() => {
        if (message.text) clearMessageAfterDelay();
    }, [message]);

    return (
        <div className="add-event-container">
            <h2>Create a New Event</h2>
            <div ref={topRef}></div>
            {message.text && (
                <p className={`message ${message.type}`} aria-live="polite">
                    {message.text}
                </p>
            )}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="eventName">Event Name</label>
                    <input
                        type="text"
                        id="eventName"
                        name="eventName"
                        value={formData.eventName}
                        onChange={handleChange}
                        required
                        maxLength="100"
                        aria-label="Event Name"
                    />
                </div>
                <div className="form-group">
                    <label>Event Date</label>
                    <Calendar
                        value={formData.eventDate}
                        onChange={handleDateChange}
                        tileClassName={tileClassName}
                        locale="en-US"
                        formatMonthYear={(locale, date) =>
                            date.toLocaleDateString("en", {
                                month: "short",
                                year: "numeric",
                            })
                        }
                        showNeighboringMonth={true}
                    />
                </div>
                {["cust1", "cust2", "cust3", "cust4"].map((field, index) => (
                    <div className="form-group" key={field}>
                        <label htmlFor={field}>{`Custom Field ${
                            index + 1
                        }`}</label>
                        <input
                            type="text"
                            id={field}
                            name={field}
                            value={formData[field]}
                            onChange={handleChange}
                            placeholder={`Custom${index + 1}`}
                            maxLength="50"
                            aria-label={`Custom Field ${index + 1}`}
                        />
                    </div>
                ))}
                <div className="form-actions">
                    <button
                        type="submit"
                        className="btn-submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Creating..." : "Create Event"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddEvent;
