// src/components/Dashboard/CreditsInfo.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../utils/routes";
import DashboardCard from "../Common/DashboardCard";

const CreditsInfo = ({ credits }) => {
    const navigate = useNavigate();
    return (
        <DashboardCard
            title="Credits"
            actionButton={
                <button
                    onClick={() => navigate(routePaths.buyCredits)}
                    className="add-btn"
                >
                    +
                </button>
            }
        >
            <p>
                <strong>Free Credits:</strong> {credits.creditsA}
            </p>
            <p>
                <strong>Purchased Credits:</strong> {credits.creditsB}
            </p>
        </DashboardCard>
    );
};

export default CreditsInfo;
