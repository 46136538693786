// src/components/Pages/Home.js
import React from "react";
import HeroSection from "../Common/HeroSection";
import FeaturesSection from "../Common/FeaturesSection";
import CallToActionSection from "./../Common/CallToActionSection";

const Home = () => {
    return (
        <div className="home-container">
            <HeroSection />
            <FeaturesSection />
            <CallToActionSection />
        </div>
    );
};

export default Home;
