import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import MainNavigation from "./MainNavigation";
import ToTopButton from "./ToTopButton";
import Breadcrumbs from "./Breadcrumbs";
import ScrollToTop from "./ScrollToTop";
import rootStyles from "./RootLayout.module.css";
import { useSelector } from "react-redux";

export default function RootLayout() {
    const [isScrolled, setIsScrolled] = useState(false);
    const userLoggedIn = Boolean(useSelector((state) => state.user));
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 10);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    // Check if the user is on the home page
    const isHomePage = location.pathname === "/";

    return (
        <>
            <header
                className={`${rootStyles.header} ${
                    isScrolled ? rootStyles.scrolled : ""
                }`}
            >
                <MainNavigation />
                {userLoggedIn && !isHomePage && <Breadcrumbs />}
            </header>
            <ScrollToTop />
            <main>
                <Outlet />
                <ToTopButton />
            </main>
        </>
    );
}
