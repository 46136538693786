// src/components/Pages/NotFound.js
import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
        <h1>404 - Page Not Found</h1>
        <p>Sorry, the page you're looking for doesn't exist.</p>
        <Link to="/" style={{ color: "#007bff", textDecoration: "underline" }}>
            Go back to Home
        </Link>
    </div>
);

export default NotFound;
