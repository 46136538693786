// src/Event/ViewEvent.js

import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { generatePathWithParams } from "../../utils/navigation";
import { routePaths } from "../../utils/routes";
import {
    fetchEventDetails,
    fetchGuests,
    deleteEvent,
    importGuestList,
    exportGuestList,
    fetchEventStats,
} from "../../utils/api";
import {
    FaUserFriends,
    FaCheckCircle,
    FaCalendarCheck,
    FaFileUpload,
    FaDownload,
    FaTrashAlt,
    FaClipboardCheck,
    FaDatabase,
} from "react-icons/fa";
import "./ViewEvent.css";
import { useSelector /* useDispatch */ } from "react-redux";
/* import { setLoading } from "../../redux/globalSlice"; */
import ColumnSettings from "../Common/ColumnSettings";

const MESSAGE_TIMEOUT = 5000;

const ViewEvent = () => {
    const { eventId } = useParams();
    const userId = useSelector((state) => state.user?.id);
    const parentUserId = useSelector((state) => state.user?.parentUser);
    const role = useSelector((state) => state.user?.role);
    const navigate = useNavigate();
    /*  const dispatch = useDispatch(); */

    const isAdmin = role === "Admin";
    const isParent = !parentUserId;
    const isAgent = role === "Agent";

    const topRef = useRef(null);

    const [eventDetails, setEventDetails] = useState({});
    const [guests, setGuests] = useState([]);
    const [stats, setStats] = useState({
        loaded: 0,
        checkedIn: 0,
        confirmed: 0,
    });
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const loadAllData = useCallback(async () => {
        /* dispatch(setLoading(true)); */
        try {
            const [eventResponse, guestsResponse, statsResponse] =
                await Promise.all([
                    fetchEventDetails(eventId, parentUserId || userId),
                    fetchGuests(eventId, parentUserId || userId),
                    fetchEventStats(eventId, parentUserId || userId),
                ]);

            // Event details
            if (eventResponse.status === "success") {
                setEventDetails(eventResponse.event);
                setStats(
                    eventResponse.stats || {
                        loaded: 0,
                        checkedIn: 0,
                        confirmed: 0,
                    }
                );
            } else {
                setError("Failed to load event details.");
            }

            // Guests
            if (guestsResponse.status === "success") {
                const guestList = guestsResponse.guests || [];
                setGuests(guestList);
                setError(""); // Clear error if guests are loaded successfully
            } else {
                setError("Error fetching guests.");
            }

            // Event stats
            if (statsResponse.status === "success") {
                setStats({
                    loaded: statsResponse.stats.imported,
                    checkedIn: statsResponse.stats.checked_in,
                    confirmed: statsResponse.stats.total_confirmed,
                });
            } else {
                setError("Failed to load event stats.");
            }
        } catch (err) {
            setError("Error loading event data. Please try again.");
            console.error("Error loading event data:", err);
        } finally {
            /* dispatch(setLoading(false)); */
            // Stop spinner
        }
    }, [eventId, userId, parentUserId]);

    useEffect(() => {
        loadAllData();
    }, [loadAllData]);

    useEffect(() => {
        if (error || success) {
            topRef.current.scrollIntoView({ behavior: "smooth" });
            const timer = setTimeout(() => {
                setError("");
                setSuccess("");
            }, MESSAGE_TIMEOUT);
            return () => clearTimeout(timer);
        }
    }, [error, success]);

    const handleDeleteEvent = useCallback(async () => {
        try {
            const response = await deleteEvent(eventId, userId);
            if (response.status === "success") {
                setSuccess("Event deleted successfully!");
                setShowDeleteModal(false);
                navigate(routePaths.dashboard);
            } else {
                setError("Failed to delete event.");
            }
        } catch {
            setError("Error deleting event. Please try again.");
        }
    }, [eventId, userId, navigate]);

    const handleDownloadSampleFile = () => {
        const customHeaders = [
            eventDetails.cust1 || "Custom1",
            eventDetails.cust2 || "Custom2",
            eventDetails.cust3 || "Custom3",
            eventDetails.cust4 || "Custom4",
        ];
        const sampleData = [
            [
                "GuestID",
                "TotalGuests",
                "AlertMessage",
                "FirstName",
                "LastName",
                "Company",
                "Email",
                "Phone",
                ...customHeaders,
            ],
            [
                "001",
                "3",
                "Media",
                "John",
                "Doe",
                "Company Inc.",
                "johndoe@example.com",
                "1234567890",
                ...customHeaders.map((header) => `${header} Data`),
            ],
        ];

        const csvContent = sampleData.map((row) => row.join(",")).join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv;charset=utf-8;",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "SampleGuestList.csv";
        link.click();
        URL.revokeObjectURL(url);
    };

    const handleImportGuests = useCallback(
        async (e) => {
            const file = e.target.files[0];
            if (!file) return;

            try {
                const response = await importGuestList(eventId, userId, file);
                if (response.status === "success") {
                    setSuccess(response.message);
                    loadAllData();
                } else {
                    setError(
                        response.message || "Failed to import guest list."
                    );
                }
            } catch {
                setError("Error importing guest list. Please try again.");
            }
        },
        [eventId, userId, loadAllData]
    );

    const handleExportGuestList = useCallback(async () => {
        try {
            await exportGuestList(eventId, userId);
            setSuccess("Guest list exported successfully!");
            setError("");
        } catch (error) {
            console.error("Export Guest List Error:", error);
            setError("Error exporting guest list. Please try again.");
        }
    }, [eventId, userId]);

    const handleCheckInGuests = () => {
        navigate(
            generatePathWithParams(routePaths.checkInGuests, { eventId }),
            { state: { userId } }
        );
    };

    return (
        <div className="view-event-container">
            <div ref={topRef}></div>
            <h1>Event: {eventDetails.event_name}</h1>
            <p>
                Date:{" "}
                {new Date(eventDetails.event_date).toLocaleDateString("en-ZA", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                })}
            </p>
            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}
            {/* Actions */}
            <div className="actions-card">
                <button className="action-link" onClick={handleCheckInGuests}>
                    <FaClipboardCheck /> Check-In Guests
                </button>
                {(isParent || isAdmin) && (
                    <>
                        <button
                            className="action-link"
                            onClick={handleExportGuestList}
                        >
                            <FaDatabase /> Export Guest List
                        </button>
                        <label className="action-link-label">
                            <FaFileUpload /> Import Guest List
                            <input
                                type="file"
                                onChange={handleImportGuests}
                                hidden
                            />
                        </label>
                        <button
                            className="action-link"
                            onClick={handleDownloadSampleFile}
                        >
                            <FaDownload /> Download Sample File
                        </button>

                        <button
                            className="action-button btn-danger"
                            onClick={() => setShowDeleteModal(true)}
                        >
                            <FaTrashAlt /> Delete Event
                        </button>
                    </>
                )}
            </div>
            {/* Stats Display */}
            <h2>Statistics</h2>
            <div className="event-stats-cards">
                <div className="stats-card">
                    <FaUserFriends className="card-icon" />
                    <div className="card-content">
                        <h6 className="stats-title">Records Loaded</h6>
                        <span className="card-number loaded">
                            {stats.loaded}
                        </span>
                    </div>
                </div>
                <div className="stats-card">
                    <FaCheckCircle className="card-icon" />
                    <div className="card-content">
                        <h6 className="stats-title">Guests Confirmed</h6>
                        <span className="card-number confirmed">
                            {stats.confirmed}
                        </span>
                    </div>
                </div>
                <div className="stats-card">
                    <FaCalendarCheck className="card-icon" />
                    <div className="card-content">
                        <h6 className="stats-title">Guests Checked-In</h6>
                        <span className="card-number checkedin">
                            {stats.checkedIn}
                        </span>
                    </div>
                </div>
            </div>

            {/* Restrict Column Settings */}
            {(!isAgent || isAdmin || isParent) && (
                <ColumnSettings eventId={eventId} />
            )}

            {/* Guest List */}
            <h2>Guest List</h2>
            <div className="view-table">
                {guests.length > 0 ? (
                    <table className="guest-list">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Guests</th>
                            </tr>
                        </thead>
                        <tbody>
                            {guests.map((guest) => (
                                <tr key={guest.id}>
                                    <td data-label="Name">
                                        {guest.first_name} {guest.last_name}
                                    </td>
                                    <td data-label="Email">{guest.email}</td>
                                    <td data-label="Guests">
                                        {guest.total_guests}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No guests loaded for this event.</p>
                )}
            </div>

            {/* Delete Confirmation Modal */}
            {showDeleteModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Are you sure you want to delete this event?</h3>
                        <p>This action cannot be undone.</p>
                        <button
                            className="modal-button-danger"
                            onClick={handleDeleteEvent}
                        >
                            Yes, Delete Event
                        </button>
                        <button
                            className="modal-button-cancel"
                            onClick={() => setShowDeleteModal(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ViewEvent;
