// src/components/Dashboard/UserInfo.js
import React from "react";
import DashboardCard from "../Common/DashboardCard";
import { toProperCase } from "../../utils/helpers";

const UserInfo = ({ userDetails }) => (
    <DashboardCard title="User Information">
        <p>
            <strong>Name:</strong> {userDetails.first_name}{" "}
            {userDetails.last_name}
        </p>
        <p>
            <strong>Email:</strong> {userDetails.email}
        </p>
        <p>
            <strong>Role:</strong> {toProperCase(userDetails.role)}
        </p>
    </DashboardCard>
);

export default UserInfo;
