// src/components/Spinner.js
import React from "react";
import "./Spinner.css";

const Spinner = ({ loading }) => {
    if (!loading) return null; // Hide spinner if not loading

    return (
        <div className="spinner-container">
            <div className="loader"></div>
        </div>
    );
};

export default Spinner;
