// src/components/Dashboard/EventsList.js
import React from "react";
import DashboardCard from "../Common/DashboardCard";
import DataTable from "../Common/DataTable";
import { useNavigate } from "react-router-dom";
import { generatePathWithParams } from "../../utils/navigation";
import { routePaths } from "../../utils/routes";
import { formatDate } from "../../utils/helpers";

const EventsList = ({ events, canCreateEvent, isParent, isAdmin }) => {
    const navigate = useNavigate();

    return (
        <DashboardCard
            title="Events"
            actionButton={
                (isParent || isAdmin) && (
                    <button
                        onClick={() => navigate(routePaths.newEvent)}
                        className={`add-btn ${
                            !canCreateEvent ? "disabled" : ""
                        }`}
                        disabled={!canCreateEvent}
                    >
                        +
                    </button>
                )
            }
        >
            <p className="error" hidden={canCreateEvent}>
                You need to purchase credits before creating a new event.
            </p>
            <DataTable
                data={events}
                columns={[
                    { label: "Event Name", field: "event_name" },
                    {
                        label: "Event Date",
                        field: "event_date",
                        formatter: (event) =>
                            event.event_date
                                ? formatDate(event.event_date)
                                : "N/A",
                    },
                ]}
                actions={[
                    {
                        label: "View",
                        class: "view",
                        onClick: (event) =>
                            navigate(
                                generatePathWithParams(routePaths.viewEvent, {
                                    eventId: event.id,
                                })
                            ),
                    },
                    ...(isParent || isAdmin
                        ? [
                              {
                                  label: "Edit",
                                  class: "edit",
                                  onClick: (event) =>
                                      navigate(
                                          generatePathWithParams(
                                              routePaths.editEvent,
                                              { eventId: event.id }
                                          )
                                      ),
                              },
                          ]
                        : []),
                ]}
            />
        </DashboardCard>
    );
};

export default EventsList;
