import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routePaths } from "../../utils/routes";
import Calendar from "react-calendar";
import { fetchEventDetails, updateEvent } from "../../utils/api";
import "../Common/Calendar.css";
import "./EditEvent.css";
import { useSelector } from "react-redux";

const MESSAGE_TIMEOUT = 5000;

const EditEvent = () => {
    const { eventId } = useParams();
    const userId = useSelector((state) => state.user?.id);
    const parentUserId = useSelector((state) => state.user?.parentUser);
    const navigate = useNavigate();
    const topRef = useRef(null);

    const [eventDetails, setEventDetails] = useState({});
    const [formData, setFormData] = useState({
        eventName: "",
        eventDate: new Date(),
        cust1: "",
        cust2: "",
        cust3: "",
        cust4: "",
    });
    const [message, setMessage] = useState({ type: "", text: "" });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const clearMessageAfterDelay = useCallback(() => {
        const timer = setTimeout(
            () => setMessage({ type: "", text: "" }),
            MESSAGE_TIMEOUT
        );
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const loadEventDetails = async () => {
            try {
                const response = await fetchEventDetails(
                    eventId,
                    parentUserId || userId
                );
                if (response.status === "success") {
                    const { event } = response;
                    setEventDetails(event);
                    setFormData({
                        eventName: event.event_name || "",
                        eventDate: new Date(event.event_date),
                        cust1: event.cust1 || "Custom1",
                        cust2: event.cust2 || "Custom2",
                        cust3: event.cust3 || "Custom3",
                        cust4: event.cust4 || "Custom4",
                    });
                } else {
                    setMessage({
                        type: "error",
                        text:
                            response.message || "Failed to load event details.",
                    });
                }
            } catch (error) {
                setMessage({
                    type: "error",
                    text: "Error loading event details. Please try again.",
                });
            }
        };

        loadEventDetails();
    }, [eventId, parentUserId, userId]);

    useEffect(() => {
        if (message.text) {
            topRef.current.scrollIntoView({ behavior: "smooth" });
            clearMessageAfterDelay();
        }
    }, [message, clearMessageAfterDelay]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMessage({ type: "", text: "" });
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleDateChange = (date) => {
        const selectedDate = new Date(date);
        const currentDate = new Date();

        // Reset the time portion of both dates to ensure the comparison is only based on the date
        selectedDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        // Check if the selected date is older than the current date
        if (selectedDate < currentDate) {
            setMessage({
                type: "error",
                text: "The selected date is in the past. Please choose a future date.",
            });
        } else {
            setMessage({ type: "", text: "" }); // Clear any previous message if the date is valid
            setFormData((prevData) => ({ ...prevData, eventDate: date }));
        }
    };

    const tileClassName = ({ date, view }) => {
        if (view === "month") {
            return ""; // Ensure no custom logic is breaking display
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const eventData = {
            event_name: formData.eventName,
            event_date: formData.eventDate.toLocaleDateString("en-ZA"),
            cust1: formData.cust1,
            cust2: formData.cust2,
            cust3: formData.cust3,
            cust4: formData.cust4,
        };

        try {
            const response = await updateEvent(eventId, eventData);
            if (
                response.status === "success" ||
                response.message.includes("successfully")
            ) {
                setMessage({
                    type: "success",
                    text: "Event updated successfully!",
                });
                setTimeout(() => navigate(routePaths.dashboard), 2000);
            } else {
                setMessage({
                    type: "error",
                    text: response.message || "Failed to update event.",
                });
            }
        } catch {
            setMessage({
                type: "error",
                text: "An error occurred while updating the event.",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="edit-event-container">
            <h1>Edit Event: {eventDetails.event_name || "Loading..."}</h1>
            <div ref={topRef}></div>
            {message.text && (
                <p className={`message ${message.type}`}>{message.text}</p>
            )}

            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="eventName">Event Name</label>
                    <input
                        type="text"
                        id="eventName"
                        name="eventName"
                        value={formData.eventName}
                        onChange={handleChange}
                        required
                        maxLength="100"
                    />
                </div>

                <div className="form-group">
                    <label>Event Date</label>
                    <Calendar
                        value={formData.eventDate}
                        onChange={handleDateChange}
                        locale="en-ZA"
                        formatMonthYear={(locale, date) =>
                            date.toLocaleDateString("en", {
                                month: "short",
                                year: "numeric",
                            })
                        }
                        tileClassName={tileClassName}
                        showNeighboringMonth={true}
                    />
                </div>

                {["cust1", "cust2", "cust3", "cust4"].map((field, idx) => (
                    <div className="form-group" key={field}>
                        <label htmlFor={field}>{`Custom Field ${
                            idx + 1
                        }`}</label>
                        <input
                            type="text"
                            id={field}
                            name={field}
                            value={formData[field]}
                            onChange={handleChange}
                            placeholder={`Custom${idx + 1}`}
                            maxLength="50"
                        />
                    </div>
                ))}

                <div className="button-group">
                    <button
                        type="submit"
                        className="btn-submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Updating..." : "Update Event"}
                    </button>
                    <button
                        type="button"
                        className="btn-cancel"
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditEvent;
