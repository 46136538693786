// src/hooks/useForm.js
import { useState } from "react";

export const useForm = (initialState, validateOnChange = null) => {
    const [formData, setFormData] = useState(initialState);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));

        // Validate field if validateOnChange is provided
        if (validateOnChange) {
            const validationError = validateOnChange(name, value);
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: validationError,
            }));
        }
    };

    const resetForm = () => {
        setFormData(initialState);
        setErrors({});
    };

    return { formData, setFormData, errors, handleChange, resetForm };
};

// Example validation function usage
// function validateField(name, value) {
//     if (name === "email" && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
//         return "Invalid email format";
//     }
//     return "";
// }
// Usage: const { formData, errors, handleChange, resetForm } = useForm(initialFormData, validateField);
