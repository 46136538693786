// utils/api.js

const BASE_URL = process.env.REACT_APP_API_URL;

const apiRequest = async (
    endpoint,
    method = "GET",
    data = null,
    isFormData = false
) => {
    const url = `${BASE_URL}${endpoint}`;
    const headers = !isFormData ? { "Content-Type": "application/json" } : {};
    const options = {
        method,
        headers,
        body: data && !isFormData ? JSON.stringify(data) : data,
    };
    // console.log("Final Payload Sent:", data);
    try {
        const response = await fetch(url, options);
        const result = await response.json();

        // Log the API response for debugging
        console.log("API Response:", result);

        // Only throw an error if response status is not OK and status is not "success"
        if (result.status && result.status !== "success") {
            // `status` exists and indicates failure
            throw new Error(result.message || "Request failed.");
        } else if (!result.status && !response.ok) {
            // No `status` field, but HTTP response is not OK
            throw new Error(result.message || "Unknown error occurred");
        }

        // Return the result for successful responses
        return result;
    } catch (error) {
        console.error("API Request Error:", error.message || error);
        throw error; // Re-throw any unexpected errors
    }
};

// Register User
export const registerUser = (data) =>
    apiRequest("/users/addUser1.php", "POST", data);

// Login User
export const loginUser = (formData) =>
    apiRequest("/users/verify.php", "POST", formData);

// Verify Email
export const verifyEmailToken = (token) =>
    apiRequest("/users/verifyEmail.php", "POST", { token });

//Add User
export const addUser = async (userData) =>
    apiRequest("/users/addUser1.php", "POST", userData);

//Fetch User Details
export const fetchUserDetails = async (userId) => {
    return apiRequest("/users/fetchUserDetails.php", "POST", {
        user_id: userId,
    });
};

//Fetch User Credits
export const getUserCredits = async (userId) => {
    return apiRequest("/users/fetchUserCredits.php", "POST", {
        user_id: userId,
    });
};

//Fetch Linked Users
export const getLinkedUsers = async (userId) => {
    return apiRequest("/users/fetchLinkedUsers.php", "POST", {
        user_id: userId,
    });
};

//Update User Details
export const updateUserDetails = async (userData) => {
    return apiRequest("/users/updateUser.php", "POST", userData);
};

//Update Password
export const updatePassword = async (userId, newPassword) => {
    return apiRequest("/users/updatePassword.php", "POST", {
        id: userId,
        password: newPassword,
    });
};

// Contact form submission
export const submitContactForm = async (formData) => {
    return apiRequest("/users/contact.php", "POST", formData);
};

export const addEvent = async (eventData) => {
    // console.log("Serialized data Sent to API:", JSON.stringify(eventData));

    // Send the correct field names expected by the backend
    return apiRequest("/event/addEvent.php", "POST", {
        event_name: eventData.event_name, // Use event_name directly
        event_date: eventData.event_date, // Use event_date directly
        cust1: eventData.cust1 || "cust1",
        cust2: eventData.cust2 || "cust2",
        cust3: eventData.cust3 || "cust3",
        cust4: eventData.cust4 || "cust4",
        type: eventData.type || 2,
        user_id: eventData.user_id,
    });
};

// Fetch User Events
export const fetchUserEvents = (userId) =>
    apiRequest("/event/fetchEvents.php", "POST", { user_id: userId });

// Fetch event details
export const fetchEventDetails = async (eventId, userId) => {
    return apiRequest("/event/fetchEvents.php", "POST", {
        user_id: userId,
        event_id: eventId,
    });
};

// Update event details
export const updateEvent = async (eventId, eventData) => {
    return apiRequest("/event/updateEvent.php", "POST", {
        event_id: eventId,
        ...eventData,
    });
};

//Custom Labels
export const fetchCustomLabels = async (eventId, userId) => {
    return apiRequest("/event/fetchEvents.php", "POST", {
        user_id: userId,
        event_id: eventId,
    });
};

//Fetch Event Stats
export const fetchEventStats = async (eventId, userId) => {
    return await apiRequest("/guest/fetchEventStats.php", "POST", {
        event_id: eventId,
        user_id: userId,
    });
};

// Delete Event
export const deleteEvent = (eventId, userId) =>
    apiRequest("/event/deleteEvent.php", "POST", {
        event_id: eventId,
        user_id: userId,
    });

// Fetch Guests for an Event
export const fetchGuests = (eventId, userId) =>
    apiRequest("/guest/fetchGuests.php", "POST", {
        event_id: eventId,
        user_id: userId,
    });

// Fetch column settings
export const fetchColumnSettings = async (eventId) => {
    return await apiRequest("/event/getColumnSettings.php", "POST", {
        event_id: eventId,
    });
};

// Save column settings
export const saveColumnSettings = async (eventId, columns) => {
    return await apiRequest("/event/saveColumnSettings.php", "POST", {
        event_id: eventId,
        columns,
    });
};

//Update Guest Details
export const updateGuestDetails = (eventId, guestId, formData) => {
    return apiRequest("/guest/updateGuest.php", "POST", {
        event_id: eventId,
        guest_id: guestId,
        ...formData,
    });
};

// Add Single Guest
export const addGuest = (guestData) =>
    apiRequest("/guest/addGuest.php", "POST", guestData);

// Check-in a single guest
export const checkInGuest = (eventId, guestId) =>
    apiRequest("/guest/checkin.php", "POST", {
        event_id: eventId,
        id: guestId,
    });

// Check-in all guests
export const checkInAllGuests = (eventId, guestId) =>
    apiRequest("/guest/checkinAll.php", "POST", {
        event_id: eventId,
        id: guestId,
    });

// Check-out a guest
export const checkOutGuest = (eventId, guestId) =>
    apiRequest("/guest/checkout.php", "POST", {
        event_id: eventId,
        id: guestId,
    });

// Cancel a guest
export const cancelGuest = (eventId, guestId) =>
    apiRequest("/guest/cancel.php", "POST", { event_id: eventId, id: guestId });

// Import Guest List
export const importGuestList = async (eventId, userId, file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("event_id", eventId);
    formData.append("user_id", userId);

    return apiRequest("/event/importGuestList.php", "POST", formData, true);
};

// Export Guest List (Handling Blob)
export const exportGuestList = async (eventId, userId) => {
    try {
        const response = await fetch(`${BASE_URL}/guest/exportGuestList.php`, {
            method: "POST",
            body: JSON.stringify({ event_id: eventId, user_id: userId }),
            headers: { "Content-Type": "application/json" },
        });

        // Check for successful response
        if (!response.ok) {
            console.error("Error exporting guest list:", response.statusText);
            throw new Error(
                `Failed to export guest list. Status: ${response.status}`
            );
        }

        // Handle the blob response for the CSV file
        const blob = await response.blob();

        // Extract filename from the headers
        const contentDisposition = response.headers.get("Content-Disposition");
        let fileName = "GuestList.csv"; // Default filename

        if (contentDisposition && contentDisposition.includes("filename=")) {
            fileName = contentDisposition
                .split("filename=")[1]
                .replace(/"/g, "");
        }

        // Create a download link for the blob
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        // Clean up the DOM
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        return { status: "success", message: "Export successful." };
    } catch (error) {
        console.error("Error exporting guest list:", error.message);
        return { status: "error", message: "Failed to export guest list." };
    }
};

export const processWebhook = async (webhookData) =>
    apiRequest("/users/processWebhook.php", "POST", webhookData);

export const initiatePayment = async (data) =>
    apiRequest("/users/initiatePayment.php", "POST", data);

export const verifyPayment = async (data) =>
    apiRequest("/users/verifyPayment.php", "POST", data);

export const deductUserCredits = async (userId) =>
    apiRequest("/users/deductCredits.php", "POST", {
        user_id: userId,
    });

export const processYocoPayment = async (amount) => {
    try {
        const response = await fetch("/api/payments/yoco", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ amount }),
        });
        const result = await response.json();
        return result.status === "success";
    } catch (error) {
        console.error("Payment Processing Error:", error);
        return false;
    }
};
