// src/components/Common/FeaturesSection.js
import React from "react";
import {
    FaClock,
    FaCheckSquare,
    FaChartBar,
    FaLock,
    FaRegSmile,
    FaEdit,
    FaSyncAlt,
    FaHeadset,
} from "react-icons/fa";
import "./FeaturesSection.css";

const FeaturesSection = () => (
    <section className="features-section">
        <h2>What We Offer:</h2>
        <ul className="features-list">
            <li>
                <FaClock /> Effortless Setup: Create events in minutes.
            </li>
            <li>
                <FaCheckSquare /> Instant Check-In: Real-time guest entries.
            </li>
            <li>
                <FaChartBar /> Actionable Insights: Track attendance instantly.
            </li>
            <li>
                <FaLock /> Rock-Solid Security: Your data stays safe.
            </li>
        </ul>

        <h2>Why Choose Zapplyst?</h2>
        <ul className="features-list">
            <li>
                <FaRegSmile /> Intuitive Design: Simple and clear for anyone.
            </li>
            <li>
                <FaEdit /> Custom-Tailored Forms: Personalize every detail.
            </li>
            <li>
                <FaSyncAlt /> Real-Time Updates: Stay in control live.
            </li>
            <li>
                <FaHeadset />
                Any ideas for improvements are quickly implemented!
            </li>
        </ul>
    </section>
);

export default FeaturesSection;
