import React, { useEffect, useState } from "react";
import { Link, useLocation, matchPath } from "react-router-dom";
import styles from "./Breadcrumbs.module.css";
import { routePaths } from "../../utils/routes";
import { useSelector } from "react-redux";
import { fetchUserDetails, fetchEventDetails } from "../../utils/api";

// Static Route Definitions
const routes = [
    { path: routePaths.home, label: "Home" },
    { path: routePaths.login, label: "Login" },
    { path: routePaths.register, label: "Register" },
    { path: routePaths.verifyEmail, label: "Verify Email" },
    { path: routePaths.dashboard, label: "Dashboard" },
    { path: routePaths.newUser, label: "Add User" },
    { path: routePaths.newEvent, label: "Add Event" },
    { path: routePaths.gettingStarted, label: "Getting Started" },
    { path: routePaths.pricing, label: "Pricing" },
    { path: routePaths.buyCredits, label: "Buy Credits" },
    {
        path: routePaths.editUser,
        label: async (params) => {
            try {
                const user = await fetchUserDetails(params.userId);
                return user?.first_name
                    ? `User: ${user.first_name} ${user.last_name}`
                    : `User ${params.userId}`;
            } catch (error) {
                console.error("Failed to fetch user details:", error);
                return `User ${params.userId}`;
            }
        },
    },
    { path: routePaths.contact, label: "Contact" },
];

// Helper Function for Event Label
async function fetchEventLabel(eventId, { userId, parentId }) {
    try {
        const event = await fetchEventDetails(eventId, parentId ?? userId);
        return event.event?.event_name || `Event ${eventId}`;
    } catch (error) {
        console.error("Failed to fetch event details:", error);
        return `Event ${eventId}`;
    }
}

// Breadcrumbs Component
const Breadcrumbs = () => {
    const location = useLocation();
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const userId = useSelector((state) => state.user?.id);
    const parentId = useSelector((state) => state.user?.parentUser ?? null);

    useEffect(() => {
        const buildBreadcrumbs = async () => {
            const paths = location.pathname.split("/").filter(Boolean);
            const breadcrumbsList = [
                { pathname: routePaths.dashboard, label: "Dashboard" },
            ];

            // Event-Specific Pages
            const eventPages = [
                routePaths.viewEvent,
                routePaths.editEvent,
                routePaths.checkInGuests,
                routePaths.guestEdit,
                routePaths.addGuest,
            ];

            const matchingEventPage = eventPages.find((path) =>
                matchPath(path, location.pathname)
            );

            if (matchingEventPage) {
                const eventId =
                    matchPath(matchingEventPage, location.pathname)?.params
                        ?.eventId || location.state?.eventId;

                if (
                    eventId &&
                    !breadcrumbsList.some(
                        (b) =>
                            b.pathname ===
                            routePaths.viewEvent.replace(":eventId", eventId)
                    )
                ) {
                    const eventName = await fetchEventLabel(eventId, {
                        userId,
                        parentId,
                    });

                    breadcrumbsList.push({
                        pathname: routePaths.viewEvent.replace(
                            ":eventId",
                            eventId
                        ),
                        label: eventName,
                    });

                    // Add Event Action Labels
                    if (matchPath(routePaths.viewEvent, location.pathname)) {
                        breadcrumbsList.push({ label: "View" });
                    }
                    if (matchPath(routePaths.editEvent, location.pathname)) {
                        breadcrumbsList.push({ label: "Edit" });
                    }
                    if (
                        matchPath(routePaths.checkInGuests, location.pathname)
                    ) {
                        breadcrumbsList.push({ label: "Checkin" });
                    }
                    if (matchPath(routePaths.guestEdit, location.pathname)) {
                        breadcrumbsList.push({ label: "Edit Guest" });
                    }
                    if (matchPath(routePaths.addGuest, location.pathname)) {
                        breadcrumbsList.push({ label: "Add Guest" });
                    }
                }
            }

            // Handle General Routes
            for (let i = 0; i < paths.length; i++) {
                const currentPath = `/${paths.slice(0, i + 1).join("/")}`;
                const routeMatch = routes.find(
                    (route) =>
                        matchPath(route.path, currentPath) && route.path !== "*"
                );

                if (routeMatch) {
                    const matchParams = matchPath(
                        routeMatch.path,
                        currentPath
                    )?.params;

                    try {
                        const label =
                            typeof routeMatch.label === "function"
                                ? await routeMatch.label(matchParams, {
                                      userId,
                                      parentId,
                                  })
                                : routeMatch.label;

                        if (
                            !breadcrumbsList.some(
                                (b) => b.pathname === currentPath
                            )
                        ) {
                            breadcrumbsList.push({
                                pathname: currentPath,
                                label,
                            });
                        }
                    } catch (error) {
                        console.error(
                            `Error resolving breadcrumb label for ${currentPath}`,
                            error
                        );
                    }
                }
            }

            setBreadcrumbs(breadcrumbsList);
        };

        buildBreadcrumbs();
    }, [location, userId, parentId]);

    return (
        <nav className={styles.breadcrumbs} aria-label="Breadcrumb">
            {breadcrumbs.map((breadcrumb, index) => (
                <span key={breadcrumb.pathname || index}>
                    {index > 0 && <span className={styles.separator}>|</span>}
                    {breadcrumb.pathname ? (
                        <Link
                            to={breadcrumb.pathname}
                            className={styles.breadcrumbLink}
                        >
                            {breadcrumb.label}
                        </Link>
                    ) : (
                        <span
                            className={styles.activeBreadcrumb}
                            aria-current="page"
                        >
                            {breadcrumb.label}
                        </span>
                    )}
                </span>
            ))}
        </nav>
    );
};

export default Breadcrumbs;
