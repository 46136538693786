import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Uses localStorage
import userSlice from "./userSlice";
import feedbackSlice from "./feedbackSlice";
import globalReducer from "./globalSlice";

// Redux Persist configuration
const persistConfig = {
    key: "root",
    storage,
    whitelist: ["user"], // Only persist specific slices, here we use 'user'
};

// Combine reducers (adding others here if needed)
const rootReducer = combineReducers({
    user: userSlice,
    feedback: feedbackSlice,
    global: globalReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);
export default store;
