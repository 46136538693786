// src/components/User/AddUser.js

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../utils/routes";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../utils/api";
import { setError, setSuccess } from "../../redux/feedbackSlice";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./AddUser.css";

const FEEDBACK_DISPLAY_DURATION = 2000;

// Helper Functions
const dispatchFeedback = (dispatch, type, message, setMessage) => {
    dispatch(type === "error" ? setError(message) : setSuccess(message));
    setMessage({ type, text: message });
};

const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 10) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/\d/.test(password)) strength++;
    if (/[!@#$%^&*]/.test(password)) strength++;

    return strength >= 3
        ? { label: "Strong", color: "green" }
        : { label: "Weak", color: "red" };
};

const generateUsername = (firstName, lastName) =>
    `${firstName.toLowerCase()}${lastName.toLowerCase()}${Math.floor(
        Math.random() * 1000
    )}`.replace(/[^a-z0-9]/g, "");

const AddUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userId = useSelector((state) => state.user?.id);

    const [message, setMessage] = useState({ type: "", text: "" });
    const [passwordStrength, setPasswordStrength] = useState({
        label: "",
        color: "",
    });
    const [showPasswords, setShowPasswords] = useState({
        password: false,
        confirmPassword: false,
    });
    const topRef = useRef(null);

    const [userFormData, setUserFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
        role: "Agent",
        skip_confirmation: true,
        parent_user_id: userId,
    });

    useEffect(() => {
        if (userFormData.first_name && userFormData.last_name) {
            const username = generateUsername(
                userFormData.first_name,
                userFormData.last_name
            );
            setUserFormData((prev) => ({ ...prev, username }));
        }
    }, [userFormData.first_name, userFormData.last_name]);

    useEffect(() => {
        setPasswordStrength(calculatePasswordStrength(userFormData.password));
    }, [userFormData.password]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserFormData((prev) => ({ ...prev, [name]: value }));
    };

    const togglePasswordVisibility = (field) => {
        setShowPasswords((prev) => ({
            ...prev,
            [field]: !prev[field],
        }));
    };

    const handleSubmitUser = async (e) => {
        e.preventDefault();
        topRef.current.scrollIntoView({ behavior: "smooth" });

        if (userFormData.password !== userFormData.confirmPassword) {
            dispatchFeedback(
                dispatch,
                "error",
                "Passwords do not match.",
                setMessage
            );
            return;
        }

        if (userFormData.password.length < 10) {
            dispatchFeedback(
                dispatch,
                "error",
                "Password must be at least 10 characters long.",
                setMessage
            );
            return;
        }

        try {
            const result = await addUser({
                ...userFormData,
                confirmPassword: undefined,
            });

            if (result.status === "success") {
                dispatchFeedback(
                    dispatch,
                    "success",
                    "User added successfully!",
                    setMessage
                );
                setTimeout(
                    () => navigate(routePaths.dashboard),
                    FEEDBACK_DISPLAY_DURATION
                );
            } else {
                dispatchFeedback(
                    dispatch,
                    "error",
                    result.message || "Failed to add user.",
                    setMessage
                );
            }
        } catch (error) {
            dispatchFeedback(
                dispatch,
                "error",
                error.message || "An error occurred while adding the user.",
                setMessage
            );
        }
    };

    return (
        <div className="add-user-container">
            <h1>Add New User</h1>
            <div ref={topRef}></div>
            {message.text && <p className={message.type}>{message.text}</p>}

            <form onSubmit={handleSubmitUser}>
                {["first_name", "last_name", "email", "username"].map(
                    (field) => (
                        <div className="form-group" key={field}>
                            <label htmlFor={field}>
                                {field
                                    .replace("_", " ")
                                    .replace(/\b\w/g, (c) => c.toUpperCase())}
                            </label>
                            <input
                                type={field === "email" ? "email" : "text"}
                                autoComplete="off"
                                id={field}
                                name={field}
                                value={userFormData[field]}
                                onChange={handleInputChange}
                                required={field !== "username"}
                                readOnly={field === "username"}
                            />
                        </div>
                    )
                )}

                {["password", "confirmPassword"].map((field) => (
                    <div className="form-group password-group" key={field}>
                        <label htmlFor={field}>
                            {field === "password"
                                ? "Password:"
                                : "Confirm Password:"}
                        </label>
                        <span className="password-input-container">
                            <input
                                type={
                                    showPasswords[field] ? "text" : "password"
                                }
                                id={field}
                                name={field}
                                value={userFormData[field]}
                                onChange={handleInputChange}
                                required
                            />
                            <span
                                className="toggle-password"
                                onClick={() => togglePasswordVisibility(field)}
                            >
                                {showPasswords[field] ? (
                                    <FaEyeSlash className="icon" />
                                ) : (
                                    <FaEye className="icon" />
                                )}
                            </span>
                        </span>
                    </div>
                ))}

                {userFormData.password && (
                    <p
                        className="password-strength"
                        style={{ color: passwordStrength.color }}
                    >
                        Password Strength: {passwordStrength.label}
                    </p>
                )}

                <div className="form-group">
                    <label htmlFor="role">User Role:</label>
                    <select
                        name="role"
                        id="role"
                        value={userFormData.role}
                        onChange={handleInputChange}
                    >
                        <option value="Agent">Agent</option>
                        <option value="Admin">Admin</option>
                    </select>
                </div>

                <button type="submit" className="btn-submit">
                    Add User
                </button>
            </form>
        </div>
    );
};

export default AddUser;
