// src/components/auth/EmailVerification.js
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { routePaths } from "../../utils/routes";
import { setUser } from "../../redux/userSlice";
import { setError, setSuccess, clearFeedback } from "../../redux/feedbackSlice";
import { verifyEmailToken } from "../../utils/api";
import "./EmailVerification.css";

const FEEDBACK_DISPLAY_DURATION = 2000; // Define a configurable feedback duration

const EmailVerification = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { error, success } = useSelector((state) => state.feedback);

    useEffect(() => {
        if (!token) {
            navigate(routePaths.home); // Redirect if no token is found
            return;
        }

        const verifyEmail = async () => {
            try {
                const data = await verifyEmailToken(token);

                if (data.message === "Email verification successful.") {
                    dispatch(
                        setUser({
                            id: data.user_id,
                            firstName: data.first_name,
                            lastName: data.last_name,
                            email: data.email,
                        })
                    );

                    dispatch(
                        setSuccess(
                            "Verification successful! Now you can Login..."
                        )
                    );
                    setTimeout(() => {
                        dispatch(clearFeedback());
                        navigate(routePaths.home);
                    }, FEEDBACK_DISPLAY_DURATION);
                } else {
                    dispatch(setError(data.message || "Verification failed."));
                }
            } catch (error) {
                dispatch(setError("Error verifying email."));
                console.error("Verification Error:", error);
            }
        };

        verifyEmail();
    }, [token, dispatch, navigate]);

    return (
        <div className="verification-container">
            <h2>Email Verification</h2>
            {/* Display feedback messages with aria-live for accessibility */}
            {error && (
                <p className="error" aria-live="polite">
                    {error}
                </p>
            )}
            {success && (
                <p className="success" aria-live="polite">
                    {success}
                </p>
            )}
            <p>
                <a href="/login">Login here!</a> &nbsp; | &nbsp;
                <a href="/register">Register here!</a>
            </p>
        </div>
    );
};

export default EmailVerification;
