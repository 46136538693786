import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { generatePathWithParams } from "../../utils/navigation";
import { routePaths } from "../../utils/routes";

import {
    fetchCustomLabels,
    fetchGuests,
    updateGuestDetails,
} from "../../utils/api";
import { useForm } from "../../hooks/useForm";
import "./GuestEdit.css";
import { useSelector /* useDispatch */ } from "react-redux";
//import { setLoading } from "../../redux/globalSlice";

const GuestEdit = () => {
    const { guestId } = useParams();
    const location = useLocation();
    const eventId = location.state?.eventId || null; // Safely access eventId
    const userId = useSelector((state) => state.user?.id);
    // const parentUserId = useSelector((state) => state.user?.parent_user_id);
    const navigate = useNavigate();
    // const dispatch = useDispatch();

    const { formData, handleChange, setFormData } = useForm({
        alert_message: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        company: "",
        total_guests: 1,
        cust1: "",
        cust2: "",
        cust3: "",
        cust4: "",
    });

    const [customLabels, setCustomLabels] = useState({});
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchGuestAndLabels = async () => {
            if (!eventId || !guestId) {
                setError("Event ID and Guest ID are required.");
                setIsLoading(false);
                // dispatch(setLoading(true));
                return;
            }

            try {
                const [labelsData, guestData] = await Promise.all([
                    fetchCustomLabels(eventId, userId),
                    fetchGuests(eventId, userId),
                ]);

                if (labelsData.status === "success") {
                    setCustomLabels({
                        cust1: labelsData.event.cust1 || "",
                        cust2: labelsData.event.cust2 || "",
                        cust3: labelsData.event.cust3 || "",
                        cust4: labelsData.event.cust4 || "",
                    });
                } else {
                    setError(
                        labelsData.message || "Failed to load event labels."
                    );
                }

                if (guestData.status === "success") {
                    const guestDetails = guestData.guests.find(
                        (guest) => guest.id.toString() === guestId
                    );
                    if (guestDetails) {
                        setFormData(guestDetails);
                    } else {
                        setError("Guest not found.");
                    }
                } else {
                    setError(
                        guestData.message || "Failed to load guest details."
                    );
                }
            } catch (err) {
                setError(
                    "Error fetching guest details or labels. Please try again."
                );
                console.error("Error:", err);
            } finally {
                setIsLoading(false);
                //  dispatch(setLoading(false));
            }
        };

        fetchGuestAndLabels();
    }, [eventId, guestId, userId, setFormData]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const data = await updateGuestDetails(eventId, guestId, formData);
            if (data.status === "success") {
                navigate(
                    generatePathWithParams(routePaths.checkInGuests, {
                        eventId,
                    }),
                    {
                        state: {
                            userId,
                            message: "Guest details updated successfully!",
                        },
                    }
                );
            } else {
                setError(data.message || "Failed to update guest details.");
            }
        } catch (err) {
            setError("Error updating guest details. Please try again.");
            console.error("Error updating guest details:", err);
        }
    };

    if (isLoading) return <p>Loading guest details...</p>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="guest-edit-container">
            <h2>Edit Guest</h2>
            <form onSubmit={handleSubmit}>
                <label>Alert Message:</label>
                <input
                    type="text"
                    name="alert_message"
                    value={formData.alert_message}
                    onChange={handleChange}
                />
                <label>First Name:</label>
                <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                />
                <label>Last Name:</label>
                <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    required
                />
                <label>Email:</label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <label>Phone:</label>
                <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    pattern="\d*"
                    title="Phone number should contain only digits"
                />
                <label>Company:</label>
                <input
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                />

                {/* Render custom fields dynamically */}
                {Object.keys(customLabels).map(
                    (field) =>
                        customLabels[field] && (
                            <div key={field}>
                                <label>{customLabels[field]}:</label>
                                <input
                                    type="text"
                                    name={field}
                                    value={formData[field] || ""}
                                    onChange={handleChange}
                                />
                            </div>
                        )
                )}

                <label>Total Guests:</label>
                <input
                    type="number"
                    name="total_guests"
                    value={formData.total_guests}
                    min="1"
                    onChange={handleChange}
                    required
                />
                <div className="button-group">
                    <button className="btn-submit" type="submit">
                        Save
                    </button>
                    <button
                        type="button"
                        className="btn-cancel"
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default GuestEdit;
