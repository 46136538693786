import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
    fetchColumnSettings,
    fetchGuests,
    checkInGuest,
    checkInAllGuests,
    checkOutGuest,
    cancelGuest,
} from "../../utils/api";
import { FaTimes } from "react-icons/fa";
import { StatsBar, GuestTable, GuestPanel } from "../../components"; // Updated
import "./CheckInGuests.css";
import { useSelector } from "react-redux";

const MESSAGE_TIMEOUT = 5000;

const CheckInGuests = () => {
    const { eventId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const userId = useSelector((state) => state.user?.id);
    const userRole = useSelector((state) => state.user?.role?.toLowerCase());
    const [message, setMessage] = useState(location.state?.message || "");
    const [guests, setGuests] = useState([]);
    const [selectedGuest, setSelectedGuest] = useState(null);
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [error, setError] = useState("");
    const [statsRefreshKey, setStatsRefreshKey] = useState(0);

    // Clear message after timeout
    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage("");
                navigate(location.pathname, {
                    replace: true,
                    state: null,
                });
            }, MESSAGE_TIMEOUT);
            return () => clearTimeout(timer);
        }
    }, [message, navigate, location.pathname]);

    // Fetch guest list and column settings
    const fetchGuestsAndSettings = useCallback(async () => {
        if (!eventId || !userId) return;
        try {
            const [columnsData, guestsData] = await Promise.all([
                fetchColumnSettings(eventId),
                fetchGuests(eventId, userId),
            ]);

            if (columnsData.status === "success") {
                setSelectedColumns(
                    columnsData.columns.filter((col) => col.is_visible)
                );
            } else {
                setError(
                    columnsData.message || "Failed to load column settings."
                );
            }

            if (guestsData.status === "success") {
                setGuests(guestsData.guests || []);
            } else {
                setError(guestsData.message || "Failed to load guest list.");
            }
        } catch (err) {
            setError("Error fetching guests or column settings.");
            console.error("Error fetching data:", err);
        }
    }, [eventId, userId]);

    useEffect(() => {
        fetchGuestsAndSettings();
    }, [fetchGuestsAndSettings]);

    const refreshData = useCallback(() => {
        fetchGuestsAndSettings();
        setStatsRefreshKey((prev) => prev + 1);
    }, [fetchGuestsAndSettings]);

    const handleRowClick = useCallback((guest) => {
        setSelectedGuest(guest);
        setIsPanelOpen(true);
    }, []);

    const closePanel = useCallback(() => setIsPanelOpen(false), []);

    const performGuestAction = useCallback(
        async (action, guestId) => {
            try {
                let response;
                switch (action) {
                    case "checkin":
                        response = await checkInGuest(eventId, guestId);
                        break;
                    case "checkinAll":
                        response = await checkInAllGuests(eventId, guestId);
                        break;
                    case "checkout":
                        response = await checkOutGuest(eventId, guestId);
                        break;
                    case "cancel":
                        response = await cancelGuest(eventId, guestId);
                        break;
                    default:
                        throw new Error("Unknown action.");
                }
                if (response.status === "success") {
                    setMessage(
                        response.message || "Action completed successfully."
                    );
                    refreshData();
                } else {
                    setError(response?.message || "Action failed.");
                }
                return response;
            } catch (error) {
                setError("Error performing action.");
                console.error("Guest action error:", error);
                throw error;
            }
        },
        [eventId, refreshData]
    );

    const handleAddGuestClick = useCallback(() => {
        navigate(`/dashboard/add-guest/${eventId}`);
    }, [navigate, eventId]);

    return (
        <div
            className={`checkin-guests-container ${
                isPanelOpen ? "panel-open" : ""
            }`}
        >
            <StatsBar
                eventId={eventId}
                refreshTrigger={statsRefreshKey}
                userId={userId}
            />
            <div className="checkin-header">
                <h2>Check-In Guests</h2>
                {userRole === "admin" && (
                    <button className="btn-info" onClick={handleAddGuestClick}>
                        + Add Guest
                    </button>
                )}
            </div>
            {message && <div className="success">{message}</div>}
            {error && <p className="error">{error}</p>}
            <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search guests..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                {searchTerm && (
                    <button
                        className="clear-btn"
                        onClick={() => setSearchTerm("")}
                        aria-label="Clear search"
                    >
                        <FaTimes className="icon" />
                    </button>
                )}
            </div>
            <div className="main-content">
                <div className="guest-table-container">
                    <GuestTable
                        guests={guests}
                        selectedColumns={selectedColumns}
                        searchTerm={searchTerm}
                        onRowClick={handleRowClick}
                    />
                </div>
                {isPanelOpen && (
                    <GuestPanel
                        isOpen={isPanelOpen}
                        guest={selectedGuest}
                        onClose={closePanel}
                        onAction={performGuestAction}
                        onActionComplete={refreshData}
                        eventId={eventId}
                    />
                )}
            </div>
        </div>
    );
};

export default CheckInGuests;
