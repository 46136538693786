// src/components/pages/Dashboard.js
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { routePaths } from "../../utils/routes";
import {
    fetchUserDetails,
    getUserCredits,
    getLinkedUsers,
    fetchUserEvents,
} from "../../utils/api";

import { clearUser } from "../../redux/userSlice";
import "./Dashboard.css";

import UserInfo from "./UserInfo";
import CreditsInfo from "./CreditsInfo";
import LinkedUsers from "./LinkedUsers";
import EventsList from "./EventsList";

const MESSAGE_TIMEOUT = 5000;

const Dashboard = () => {
    const [userDetails, setUserDetails] = useState({});
    const [credits, setCredits] = useState({ creditsA: 0, creditsB: 0 });
    const [linkedUsers, setLinkedUsers] = useState([]);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const topRef = useRef(null);

    const userId = useSelector((state) => state.user?.id);
    const parentUserId = useSelector((state) => state.user?.parentUser);
    const role = useSelector((state) => state.user?.role);
    const [canCreateEvent, setCanCreateEvent] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");

    const isAdmin = role === "Admin";
    const isParent = !parentUserId;

    useEffect(() => {
        if (status === "success") {
            setSuccess("Payment was successful!");
        } else if (status === "failure") {
            setError("Payment failed. Please try again.");
        } else if (status === "cancelled") {
            setError("User Cancelled the payment.");
        }
    }, [status]);

    useEffect(() => {
        // Enable Add Event Button if creditsB > 0
        setCanCreateEvent(credits.creditsB > 0 || credits.creditsA > 0);
    }, [credits]);

    useEffect(() => {
        if (!userId) {
            navigate(routePaths.login);
            return;
        }

        const fetchDashboardData = async () => {
            setLoading(true);
            // dispatch(setLoading(true));
            try {
                const [userData, userCredits, linkedUsersData, eventsData] =
                    await Promise.all([
                        fetchUserDetails(userId),
                        getUserCredits(parentUserId || userId),
                        getLinkedUsers(parentUserId || userId),
                        fetchUserEvents(parentUserId || userId),
                    ]);

                // User Details
                if (userData) setUserDetails(userData);
                // console.log("UserData", userData);

                // User Credits
                if (userCredits) setCredits(userCredits);

                // Linked Users
                if (linkedUsersData && linkedUsersData.linked_users) {
                    setLinkedUsers(linkedUsersData.linked_users);
                }

                // Events
                if (eventsData && eventsData.events)
                    setEvents(eventsData.events);
            } catch (err) {
                setError("Failed to load dashboard data.");
                console.error("API Request Error:", err);
                dispatch(clearUser());
                localStorage.clear();
                navigate(routePaths.login);
            } finally {
                setLoading(false);
                // dispatch(setLoading(false));
            }
        };

        fetchDashboardData();
    }, [navigate, dispatch, userId, parentUserId]);

    useEffect(() => {
        if (error || success) {
            if (topRef.current) {
                topRef.current.scrollIntoView({ behavior: "smooth" });
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" }); // Fallback
            }
            const timer = setTimeout(() => {
                setError("");
                setSuccess("");
            }, MESSAGE_TIMEOUT);
            return () => clearTimeout(timer);
        }
    }, [error, success]);

    if (loading) return <p>Loading dashboard...</p>;

    return (
        <div className="dashboard-container">
            <header className="dashboard-header">
                <h2>
                    Welcome, {userDetails.first_name} {userDetails.last_name}
                </h2>
                <div ref={topRef}></div>
                {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p>}
            </header>
            <div className="dashboard-grid">
                <UserInfo userDetails={userDetails} />

                {/* Conditional Rendering for Credits */}
                {isParent && (
                    <CreditsInfo
                        credits={credits}
                        userId={userId}
                        setCredits={credits}
                    />
                )}

                {/* Conditional Rendering for Linked Users */}
                {isParent && <LinkedUsers linkedUsers={linkedUsers} />}

                {/* Events Section */}
                <EventsList
                    events={events}
                    canCreateEvent={canCreateEvent}
                    isParent={isParent}
                    isAdmin={isAdmin}
                />
            </div>
        </div>
    );
};

export default Dashboard;
