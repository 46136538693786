// src/components/Dashboard/LinkedUsers.js
import React from "react";
import { useNavigate } from "react-router-dom";
import DashboardCard from "../Common/DashboardCard";
import DataTable from "../Common/DataTable";
import { generatePathWithParams } from "../../utils/navigation";
import { routePaths } from "../../utils/routes";

const LinkedUsers = ({ linkedUsers }) => {
    const navigate = useNavigate();

    return (
        <DashboardCard
            title="Linked Users"
            actionButton={
                <button
                    onClick={() => navigate(routePaths.newUser)}
                    className="add-btn"
                >
                    +
                </button>
            }
        >
            <DataTable
                data={linkedUsers}
                columns={[
                    {
                        label: "User Name",
                        field: "first_name",
                        formatter: (row) =>
                            `${row.first_name} ${row.last_name}`,
                    },
                    { label: "Role", field: "role" },
                ]}
                actions={[
                    {
                        label: "Change Password",
                        class: "view",
                        onClick: (user) =>
                            navigate(
                                generatePathWithParams(
                                    routePaths.changePassword,
                                    { userId: user.id }
                                )
                            ),
                    },
                    {
                        label: "Edit",
                        class: "edit",
                        onClick: (user) =>
                            navigate(
                                generatePathWithParams(routePaths.editUser, {
                                    userId: user.id,
                                })
                            ),
                    },
                ]}
            />
        </DashboardCard>
    );
};

export default LinkedUsers;
