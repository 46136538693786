import React from "react";
import "./Pricing.css";
import BuyCredits from "../User/BuyCredits";
import { FaHourglassHalf, FaPiggyBank, FaSyncAlt } from "react-icons/fa";

const Pricing = () => {
    return (
        <div className="pricing-container">
            <h1 className="pricing-header">
                Choose the Perfect Plan for Your Business!
            </h1>
            <p className="pricing-subheader">
                At Zapplyst, we’ve made event planning simple and flexible.{" "}
                <br />
                <strong>One credit = one event</strong>, and the best part?{" "}
                <span className="highlight">They never expire.</span>
            </p>

            <BuyCredits />
            <div className="pricing-footer">
                <h3>Why Choose Our Credit Plans?</h3>
                <div className="pricing-footer-container">
                    <div className="footer-card">
                        <FaSyncAlt className="pricing-icon" />
                        <strong>Flexibility</strong>
                        <p>Use credits when and how you need them.</p>
                    </div>
                    <div className="footer-card">
                        <FaHourglassHalf className="pricing-icon" />
                        <strong>No Expiry</strong>
                        <p>Your credits are yours to keep – no time limits.</p>
                    </div>
                    <div className="footer-card">
                        <FaPiggyBank className="pricing-icon" />
                        <strong>Cost-Effective</strong>
                        <p>The more you buy, the more you save.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pricing;
