// src/context/UserContext.js
import React, { createContext, useState, useEffect } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    // Initialize user state with data from localStorage if available
    const [user, setUser] = useState(() => {
        try {
            const savedUser = localStorage.getItem("user");
            return savedUser ? JSON.parse(savedUser) : null;
        } catch (error) {
            console.error("Error parsing user data from localStorage:", error);
            return null; // Default to null if parsing fails
        }
    });

    // Save user data to localStorage whenever it changes
    useEffect(() => {
        if (user && user.user_id) {
            // Store full user data, including user_id
            localStorage.setItem("user", JSON.stringify(user));
            localStorage.setItem("user_id", user.user_id);
            /* console.log(
                "Raw localStorage user value:",
                localStorage.getItem("user")
            ); */
        } else {
            // Clear localStorage if user logs out
            localStorage.removeItem("user");
            localStorage.removeItem("user_id");
            localStorage.removeItem("event_id");
        }
    }, [user]);

    const updateUser = (userData) => {
        setUser(userData);
    };

    const clearUser = () => {
        setUser(null); // Reset user state
    };

    return (
        <UserContext.Provider value={{ user, updateUser, clearUser }}>
            {children}
        </UserContext.Provider>
    );
};
