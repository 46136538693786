// src/components/User/Register.js
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { routePaths } from "../../utils/routes";
import { registerUser } from "../../utils/api";
import { setError, setSuccess } from "../../redux/feedbackSlice";
import { setLoading } from "../../redux/globalSlice";
import { useForm } from "../../hooks/useForm";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./Register.css";

const MESSAGE_TIMEOUT = 5000;

// Helper to dispatch feedback messages
const dispatchFeedback = (dispatch, type, message, setFeedback) => {
    if (type === "error") {
        dispatch(setError(message));
        setFeedback(message);
    } else {
        dispatch(setSuccess(message));
        setFeedback(message);
    }
};

// Helper to generate a username
const generateUsername = (firstName, lastName) => {
    const randomNum = Math.floor(Math.random() * 100);
    return `${firstName.slice(0, 5).toLowerCase()}${lastName
        .slice(0, 5)
        .toLowerCase()}${randomNum}`;
};

// Validation function
const validateForm = ({ email, email2, password, password2 }) => {
    if (!email || !email2 || !password || !password2)
        return "All fields are required.";
    if (email !== email2) return "Emails don't match";
    if (password !== password2) return "Passwords don't match";

    const passwordRegex =
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/;

    if (!passwordRegex.test(password)) {
        return "Password must be at least 10 characters long, include a capital letter, a numeric digit, and a special character.";
    }
    return null;
};

// Password strength calculation helper
const calculatePasswordStrength = (password) => {
    let strength = 0;
    const hasLength = password.length >= 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);

    if (hasLength) strength++;
    if (hasUppercase) strength++;
    if (hasNumber) strength++;
    if (hasSpecialChar) strength++;

    // Adjust strength labels
    if (!hasLength) return { label: "Too Short", color: "red" };
    if (strength < 2) return { label: "Weak", color: "orange" };
    if (strength === 2) return { label: "Medium", color: "blue" };
    if (strength >= 3 && hasUppercase && hasNumber && hasSpecialChar) {
        return { label: "Strong", color: "green" };
    }
    return { label: "Medium", color: "blue" };
};

const Register = () => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [passwordStrength, setPasswordStrength] = useState({
        label: "",
        color: "",
    });
    const [showPasswords, setShowPasswords] = useState({
        password: false,
        password2: false,
    });
    const topRef = useRef(null);
    const [disableButtons, setDisableButtons] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { formData, handleChange, resetForm } = useForm({
        firstName: "",
        lastName: "",
        email: "",
        email2: "",
        password: "",
        password2: "",
    });

    // Monitor password changes and calculate strength
    useEffect(() => {
        const strength = calculatePasswordStrength(formData.password);
        setPasswordStrength(strength);
    }, [formData.password]);

    const togglePasswordVisibility = (field) => {
        setShowPasswords((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationError = validateForm(formData);
        if (validationError) {
            setError(validationError);
            dispatchFeedback(dispatch, "error", validationError, setError);
            return;
        }
        try {
            const registrationData = {
                first_name: formData.firstName,
                last_name: formData.lastName,
                email: formData.email,
                password: formData.password,
                username: generateUsername(
                    formData.firstName,
                    formData.lastName
                ),
                role: "Admin",
            };

            dispatch(setLoading(true));
            const response = await registerUser(registrationData);

            if (response.status === "success") {
                setSuccess(
                    "Registration successful! Check your email for verification."
                );
                dispatchFeedback(
                    dispatch,
                    "success",
                    "Registration successful! Check your email for verification.",
                    setSuccess
                );
                resetForm();
                setTimeout(() => navigate(routePaths.login), 2000);
            } else {
                setError(response.message);
                dispatchFeedback(dispatch, "error", response.message, setError);
            }
        } catch (error) {
            setError(
                error.message ||
                    "An unexpected error occurred. Please try again."
            );
            dispatchFeedback(dispatch, "error", error.message, setError);
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (error || success) {
            setDisableButtons(true);
            topRef.current.scrollIntoView({ behavior: "smooth" });
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            const timer = setTimeout(() => {
                setError("");
                setSuccess("");
                setDisableButtons(false);
            }, MESSAGE_TIMEOUT);
            return () => clearTimeout(timer);
        }
    }, [error, success]);

    return (
        <div className="register-container">
            <div ref={topRef}></div>
            <h2>Register</h2>
            <div className="message">
                {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p>}
            </div>

            <form onSubmit={handleSubmit}>
                {["firstName", "lastName", "email", "email2"].map(
                    (field, idx) => (
                        <input
                            key={idx}
                            type="text"
                            name={field}
                            placeholder={field
                                .replace(/\d/g, "")
                                .replace(/([A-Z])/g, " $1")
                                .trim()
                                .replace(/\b\w/g, (char) => char.toUpperCase())}
                            value={formData[field]}
                            onChange={handleChange}
                            required
                        />
                    )
                )}
                <p>
                    <span>
                        Password must be at least 10 characters long, include a
                        capital letter, a numeric digit, and a special
                        character.
                    </span>
                </p>
                {["password", "password2"].map((field, idx) => (
                    <div key={idx} className="password-input-container">
                        <input
                            type={showPasswords[field] ? "text" : "password"}
                            name={field}
                            placeholder={field
                                .replace(/\d/g, "")
                                .replace(/([A-Z])/g, " $1")
                                .trim()
                                .replace(/\b\w/g, (char) => char.toUpperCase())}
                            value={formData[field]}
                            onChange={handleChange}
                            required
                        />
                        <span
                            className="toggle-password"
                            onClick={() => togglePasswordVisibility(field)}
                        >
                            {showPasswords[field] ? (
                                <FaEyeSlash className="icon" />
                            ) : (
                                <FaEye className="icon" />
                            )}
                        </span>
                    </div>
                ))}
                {formData.password && (
                    <p
                        className="password-strength"
                        style={{ color: passwordStrength.color }}
                    >
                        Password Strength: {passwordStrength.label}
                    </p>
                )}
                <button
                    className="btn-submit"
                    type="submit"
                    disabled={disableButtons}
                >
                    Register
                </button>
            </form>
            <p>
                Already have an account? <a href="/login">Login here!</a>
            </p>
        </div>
    );
};

export default Register;
