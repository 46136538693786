// src/Guest/GuestTable.js

import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import "./GuestTable.css";

const GuestTable = ({ guests, selectedColumns, searchTerm, onRowClick }) => {
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");

    // Handle sorting when a column header is clicked
    const handleSort = (columnName) => {
        if (sortColumn === columnName) {
            // Toggle sort order if the same column is clicked
            setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
        } else {
            // Set new column and default to ascending order
            setSortColumn(columnName);
            setSortOrder("asc");
        }
    };

    // Memorize filtered guests to avoid recalculating on every render
    const filteredAndSortedGuests = useMemo(() => {
        let result = guests;
        // Apply search filter
        if (searchTerm && selectedColumns.length) {
            result = result.filter((guest) =>
                selectedColumns.some((col) =>
                    guest[col.column_name]
                        ?.toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                )
            );
        }

        // Apply sorting
        if (sortColumn) {
            result = [...result].sort((a, b) => {
                const valueA = a[sortColumn] || "";
                const valueB = b[sortColumn] || "";
                if (valueA < valueB) return sortOrder === "asc" ? -1 : 1;
                if (valueA > valueB) return sortOrder === "asc" ? 1 : -1;
                return 0;
            });
        }

        return result;
    }, [guests, selectedColumns, searchTerm, sortColumn, sortOrder]);

    // console.log(guests);
    return (
        <div className="guest-table-container">
            <table className="guest-table">
                <thead>
                    <tr>
                        <th>Checked</th>
                        {selectedColumns.map((col) => (
                            <th
                                key={col.column_name}
                                onClick={() => handleSort(col.column_name)}
                                className={
                                    sortColumn === col.column_name
                                        ? `sortable ${
                                              sortOrder === "asc"
                                                  ? "ascending"
                                                  : "descending"
                                          }`
                                        : "sortable"
                                }
                            >
                                {col.display_name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filteredAndSortedGuests.length > 0 ? (
                        filteredAndSortedGuests.map((guest) => (
                            <tr
                                key={guest.id}
                                onClick={() => onRowClick(guest)}
                            >
                                <td data-label="Checked">
                                    {guest.checkin_guests}
                                </td>
                                {selectedColumns.map((col) => (
                                    <td
                                        key={col.column_name}
                                        data-label={col.display_name}
                                    >
                                        {guest[col.column_name]}
                                    </td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td
                                colSpan={selectedColumns.length + 1}
                                className="no-results"
                            >
                                No results found
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

GuestTable.propTypes = {
    guests: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedColumns: PropTypes.arrayOf(
        PropTypes.shape({
            column_name: PropTypes.string.isRequired,
            display_name: PropTypes.string.isRequired,
        })
    ).isRequired,
    searchTerm: PropTypes.string.isRequired,
    onRowClick: PropTypes.func.isRequired,
};

export default GuestTable;
