// src/components/StatsBar.js
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { fetchEventStats } from "../../utils/api";
import {
    FaUserCheck,
    FaWalking,
    FaClock,
    FaUsers,
    FaBan,
} from "react-icons/fa";
import "./StatsBar.css";

const StatsBar = ({ eventId, userId, refreshTrigger }) => {
    const [stats, setStats] = useState({
        imported: 0,
        walkin: 0,
        late_addition: 0,
        checked_in: 0,
        no_show: 0,
        total_confirmed: 0,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const loadStats = useCallback(async () => {
        setLoading(true);
        setError("");
        try {
            const response = await fetchEventStats(
                eventId,
                userId,
                refreshTrigger
            );
            if (response.status === "success" && response.stats) {
                const {
                    imported,
                    walkin,
                    late_addition,
                    checked_in,
                    no_show,
                    total_confirmed,
                } = response.stats;
                const totalGuests =
                    parseInt(imported, 10) +
                    parseInt(walkin, 10) +
                    parseInt(late_addition, 10);
                setStats({
                    imported: parseInt(imported, 10) || 0,
                    walkin: parseInt(walkin, 10) || 0,
                    late_addition: parseInt(late_addition, 10) || 0,
                    checked_in: parseInt(checked_in, 10) || 0,
                    no_show: parseInt(no_show, 10) || 0,
                    total_guests: totalGuests || 0,
                    total_confirmed: parseInt(total_confirmed, 10) || 0,
                });
            } else {
                setError("Could not load stats data.");
            }
        } catch (err) {
            console.error("Error fetching stats:", err);
            setError("Error fetching stats data. Please try again.");
        } finally {
            setLoading(false);
        }
    }, [eventId, userId, refreshTrigger]);

    useEffect(() => {
        loadStats();
    }, [loadStats]);

    const checkedInPercentage = useMemo(
        () =>
            stats.total_guests > 0
                ? ((stats.checked_in / stats.total_guests) * 100).toFixed(1)
                : "0",
        [stats.checked_in, stats.total_guests]
    );

    if (loading) return <p className="loading">Loading stats...</p>;
    if (error) return <p className="error">{error}</p>;

    return (
        <div className="stats-bar-container" aria-label="Event Statistics">
            <div className="stats-grid">
                <StatsCard
                    icon={<FaUserCheck />}
                    title="Checked In Guests"
                    value={`${stats.checked_in} / ${stats.total_guests}`}
                    additionalContent={
                        <div>
                            <div
                                className="progress-bar"
                                aria-label="Checked In Progress"
                            >
                                <div
                                    className="progress-fill"
                                    style={{ width: `${checkedInPercentage}%` }}
                                    aria-valuenow={checkedInPercentage}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                />
                            </div>
                            <p className="percentage-label">
                                {checkedInPercentage}% Checked In
                            </p>
                        </div>
                    }
                />
                <StatsCard
                    icon={<FaUsers />}
                    title="Imported Guests"
                    value={stats.imported}
                />
                <StatsCard
                    icon={<FaWalking />}
                    title="Walk-in Guests"
                    value={stats.walkin}
                />
                <StatsCard
                    icon={<FaClock />}
                    title="Late Additions"
                    value={stats.late_addition}
                />
                <StatsCard
                    icon={<FaBan />}
                    title="No Show"
                    value={stats.no_show}
                    className="no-show"
                />
            </div>
        </div>
    );
};

const StatsCard = ({
    icon,
    title,
    value,
    additionalContent,
    className = "",
}) => (
    <div className={`stats-card ${className}`}>
        <span className="stats-icon">{icon}</span>
        <div>
            <h4>{title}</h4>
            <p className="stats-number">{value}</p>
            {additionalContent}
        </div>
    </div>
);

export default StatsBar;
