import React, { useState, useEffect } from "react";
import { fetchColumnSettings, saveColumnSettings } from "../../utils/api";
import { useForm } from "../../hooks/useForm";
import "./ColumnSettings.css";

const MESSAGE_TIMEOUT = 5000;

const ColumnSettings = ({ eventId }) => {
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [statusMessage, setStatusMessage] = useState({ type: "", text: "" });
    const { formData: selectedColumns, setFormData } = useForm([]);

    useEffect(() => {
        const loadColumnSettings = async () => {
            try {
                const response = await fetchColumnSettings(eventId);
                if (response.status === "success") {
                    setColumns(response.columns);
                    setFormData(
                        response.columns
                            .filter((col) => col.is_visible)
                            .map((col) => col.column_name)
                    );
                    setStatusMessage({ type: "", text: "" });
                } else {
                    setStatusMessage({
                        type: "error",
                        text: "Failed to load column settings.",
                    });
                }
            } catch {
                setStatusMessage({
                    type: "error",
                    text: "Error loading column settings.",
                });
            } finally {
                setLoading(false);
            }
        };

        loadColumnSettings();
    }, [eventId, setFormData]);

    useEffect(() => {
        if (statusMessage.text) {
            const timer = setTimeout(() => {
                setStatusMessage({ type: "", text: "" });
            }, MESSAGE_TIMEOUT);

            return () => clearTimeout(timer);
        }
    }, [statusMessage]);

    const handleCheckboxChange = (columnName) => {
        setFormData((prev) =>
            prev.includes(columnName)
                ? prev.filter((col) => col !== columnName)
                : [...prev, columnName]
        );
    };

    const handleSave = async () => {
        try {
            const response = await saveColumnSettings(eventId, selectedColumns);
            setStatusMessage(
                response.status === "success"
                    ? { type: "success", text: "Settings saved successfully!" }
                    : { type: "error", text: "Failed to save settings." }
            );
        } catch {
            setStatusMessage({
                type: "error",
                text: "Error saving settings.",
            });
        }
    };

    const chunkColumns = (cols, size) =>
        cols.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / size);
            if (!resultArray[chunkIndex]) resultArray[chunkIndex] = [];
            resultArray[chunkIndex].push(item);
            return resultArray;
        }, []);

    if (loading) return <p>Loading column settings...</p>;

    return (
        <div className="column-settings-container">
            <h2>Select Columns for Check-In</h2>
            <p>Select which columns you need to search for guests</p>
            {statusMessage.text && (
                <p className={`status-message ${statusMessage.type}`}>
                    {statusMessage.text}
                </p>
            )}
            <table className="column-settings-table">
                <tbody>
                    {chunkColumns(columns, 2).map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((col) => (
                                <React.Fragment key={col.column_name}>
                                    <td className="checkbox-cell">
                                        <input
                                            type="checkbox"
                                            id={col.column_name}
                                            checked={selectedColumns.includes(
                                                col.column_name
                                            )}
                                            onChange={() =>
                                                handleCheckboxChange(
                                                    col.column_name
                                                )
                                            }
                                            aria-label={`Toggle visibility for ${col.display_name}`}
                                            className="custom-checkbox-input"
                                        />
                                        <label
                                            htmlFor={col.column_name}
                                            className="custom-checkbox-label"
                                        ></label>
                                    </td>

                                    <td className="label-cell">
                                        <label htmlFor={col.column_name}>
                                            {col.display_name}
                                        </label>
                                    </td>
                                </React.Fragment>
                            ))}
                            {row.length < 2 && <td colSpan={2}></td>}
                        </tr>
                    ))}
                </tbody>
            </table>
            <button
                className="save-column-settings-btn"
                onClick={handleSave}
                aria-label="Save column settings"
            >
                Save Column Settings
            </button>
        </div>
    );
};

export default ColumnSettings;
