import React from "react";
import PropTypes from "prop-types";
import "./DashboardCard.css";

const DashboardCard = ({
    headingLevel: Heading = "h2",
    title = "Dashboard Card",
    actionButton = null,
    children,
}) => (
    <div className="dashboard-card">
        <div className="card-header">
            <Heading className="card-title">{title}</Heading>
            {actionButton && (
                <div
                    className="card-action"
                    aria-label={`Action for ${title}`}
                    title={`Action for ${title}`}
                >
                    {actionButton}
                </div>
            )}
        </div>
        <div className="dashboard-body">{children}</div>
    </div>
);

DashboardCard.propTypes = {
    title: PropTypes.string,
    headingLevel: PropTypes.elementType, // Customizable heading level
    actionButton: PropTypes.node,
    children: PropTypes.node.isRequired,
};

export default DashboardCard;
