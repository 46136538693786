import React from "react";
import PropTypes from "prop-types";
import "./DataTable.css";

const DataTable = ({ data, columns, actions }) => {
    if (data.length === 0) {
        return <p className="no-data">No data available</p>;
    }

    return (
        <div className="table-container">
            <table className="table">
                <thead>
                    <tr className="header-row">
                        {columns.map((col) => (
                            <th key={col.field}>{col.label}</th>
                        ))}
                        {actions && <th>Actions</th>}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.id || item.key || Math.random()}>
                            {columns.map((col) => (
                                <td key={col.field} data-label={col.label}>
                                    {col.formatter
                                        ? col.formatter(item)
                                        : item[col.field]}
                                </td>
                            ))}
                            {actions && (
                                <td data-label="Actions">
                                    <div className="actions-container">
                                        {actions.map((action, idx) => (
                                            <button
                                                key={idx}
                                                onClick={() =>
                                                    action.onClick(item)
                                                }
                                                className={`action-btn action-${action.class.toLowerCase()}`}
                                                aria-label={`Perform ${action.label} Action`}
                                                title={`Perform ${action.label}`}
                                            >
                                                {action.label}
                                            </button>
                                        ))}
                                    </div>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

DataTable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            field: PropTypes.string.isRequired,
            formatter: PropTypes.func,
        })
    ).isRequired,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
            class: PropTypes.string.isRequired,
        })
    ),
};

export default DataTable;
