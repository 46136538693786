// src/components/Guest/AddGuest.js

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { generatePathWithParams } from "../../utils/navigation";
import { routePaths } from "../../utils/routes";

import { fetchCustomLabels, addGuest, checkInAllGuests } from "../../utils/api";
import { useForm } from "../../hooks/useForm";
import "./AddGuest.css";
import { useSelector /* useDispatch */ } from "react-redux";
//import { setLoading } from "../../redux/globalSlice";

const AddGuest = () => {
    const { eventId } = useParams();
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    //  const parentUserId = useSelector((state) => state.user?.parent_user_id);
    const userId = useSelector((state) => state.user?.id);
    const [customLabels, setCustomLabels] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const ERROR_TIMEOUT_DURATION = 5000;

    const { formData, handleChange, resetForm } = useForm({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        company: "",
        cust1: "",
        cust2: "",
        cust3: "",
        cust4: "",
        total_guests: 1,
    });

    useEffect(() => {
        // dispatch(setLoading(false));
        const fetchLabels = async () => {
            if (!eventId) {
                setError("Event ID is required.");
                return;
            }

            try {
                const labelsData = await fetchCustomLabels(eventId, userId);
                if (labelsData.status === "success") {
                    setCustomLabels({
                        cust1: labelsData.event.cust1 || "",
                        cust2: labelsData.event.cust2 || "",
                        cust3: labelsData.event.cust3 || "",
                        cust4: labelsData.event.cust4 || "",
                    });
                } else {
                    setError(
                        labelsData.message || "Failed to load event labels."
                    );
                }
            } catch (err) {
                setError("Error fetching labels. Please try again.");
                console.error("Fetch labels error:", err);
            }
        };

        fetchLabels();
    }, [eventId, userId]);

    useEffect(() => {
        if (error) {
            const timer = setTimeout(
                () => setError(""),
                ERROR_TIMEOUT_DURATION
            );
            return () => clearTimeout(timer);
        }
    }, [error]);

    const isValidForm = () => {
        return formData.first_name && formData.last_name && eventId && userId;
    };

    const handleAddGuest = async (type) => {
        setIsLoading(true);
        //  dispatch(setLoading(true));

        if (!isValidForm()) {
            setError("Please complete all required fields.");
            return;
        }

        const payload = {
            ...formData,
            event_id: eventId,
            user_id: userId,
            type,
        };

        console.log("Payload being sent to addGuest API:", payload); // Log the payload

        try {
            const response = await addGuest(payload);
            if (response.status === "success") {
                if (type === "Walkin" && response.guest_id) {
                    await checkInAllGuests(eventId, response.guest_id);
                }
                resetForm();
                navigate(
                    generatePathWithParams(routePaths.checkInGuests, {
                        eventId,
                    }),
                    {
                        state: {
                            userId, // Pass state for navigation
                            message: `${type} guest added successfully!`,
                        },
                    }
                );
            } else {
                setError(response.message || "Failed to add guest.");
            }
        } catch (err) {
            setError("Error adding guest. Please try again.");
            console.error("Add guest error:", err);
        } finally {
            setIsLoading(false);
            //  dispatch(setLoading(false));
        }
    };

    const renderCustomField = (fieldName, label) => (
        <div key={fieldName}>
            <label>{label}:</label>
            <input
                type="text"
                name={fieldName}
                value={formData[fieldName]}
                onChange={(e) => {
                    setError("");
                    handleChange(e);
                }}
            />
        </div>
    );

    return (
        <div className="add-guest-container">
            <h2>Add Guest</h2>
            {error && <div className="error">{error}</div>}
            <form>
                <label>First Name:</label>
                <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={(e) => {
                        setError("");
                        handleChange(e);
                    }}
                    required
                />

                <label>Last Name:</label>
                <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={(e) => {
                        setError("");
                        handleChange(e);
                    }}
                    required
                />

                <label>Email:</label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => {
                        setError("");
                        handleChange(e);
                    }}
                    required
                />

                <label>Phone:</label>
                <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={(e) => {
                        setError("");
                        handleChange(e);
                    }}
                    pattern="\d*"
                    title="Phone number should contain only digits"
                />

                <label>Company:</label>
                <input
                    type="text"
                    name="company"
                    value={formData.company}
                    onChange={(e) => {
                        setError("");
                        handleChange(e);
                    }}
                />

                {/* Render custom fields dynamically */}
                {Object.entries(customLabels).map(([field, label]) =>
                    label ? renderCustomField(field, label) : null
                )}

                <label>Total Guests:</label>
                <input
                    type="number"
                    name="total_guests"
                    value={formData.total_guests}
                    min="1"
                    onChange={(e) => {
                        setError("");
                        handleChange(e);
                    }}
                    required
                />
                <div className="button-group">
                    <button
                        className="btn-info"
                        type="button"
                        onClick={() => handleAddGuest("Late Addition")}
                        disabled={!eventId || isLoading}
                    >
                        Add Guest
                    </button>
                    <button
                        className="btn-success"
                        type="button"
                        onClick={() => handleAddGuest("Walkin")}
                        disabled={!eventId || isLoading}
                    >
                        Add & Check-in Guest
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddGuest;
