import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routePaths } from "../../utils/routes";
import { updatePassword } from "../../utils/api";
import { useDispatch } from "react-redux";
import { setSuccess } from "../../redux/feedbackSlice";
import { useForm } from "../../hooks/useForm";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Icons for password toggle
import "./ChangePassword.css";

const FEEDBACK_DISPLAY_DURATION = 2500;

// Helper function to calculate password strength
const calculatePasswordStrength = (password) => {
    let strength = 0;
    const hasLength = password.length >= 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*]/.test(password);

    if (hasLength) strength++;
    if (hasUppercase) strength++;
    if (hasNumber) strength++;
    if (hasSpecialChar) strength++;

    // Adjust strength labels
    if (!hasLength) return { label: "Too Short", color: "red" };
    if (strength < 2) return { label: "Weak", color: "orange" };
    if (strength === 2) return { label: "Medium", color: "blue" };
    if (strength >= 3 && hasUppercase && hasNumber && hasSpecialChar) {
        return { label: "Strong", color: "green" };
    }
    return { label: "Medium", color: "blue" };
};

const ChangePassword = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const topRef = useRef(null);

    const { formData, handleChange, resetForm } = useForm({
        newPassword: "",
        confirmNewPassword: "",
    });

    const [statusMessage, setStatusMessage] = useState({ type: "", text: "" });
    const [showPasswords, setShowPasswords] = useState({
        newPassword: false,
        confirmNewPassword: false,
    });
    const [passwordStrength, setPasswordStrength] = useState({
        label: "",
        color: "",
    });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (formData.newPassword) {
            const strength = calculatePasswordStrength(formData.newPassword);
            setPasswordStrength(strength);
        } else {
            setPasswordStrength({ label: "", color: "" });
        }
    }, [formData.newPassword]);

    useEffect(() => {
        if (statusMessage) {
            topRef.current.scrollIntoView({ behavior: "smooth" });
            const timer = setTimeout(
                () => setStatusMessage({ type: "", text: "" }),
                FEEDBACK_DISPLAY_DURATION
            );
            return () => clearTimeout(timer);
        }
    }, [statusMessage]);

    const togglePasswordVisibility = (field) => {
        setShowPasswords((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    const handlePasswordUpdate = async (e) => {
        e.preventDefault();
        if (formData.newPassword !== formData.confirmNewPassword) {
            setStatusMessage({
                type: "error",
                text: "Passwords do not match.",
            });
            return;
        }
        if (formData.newPassword.length < 8) {
            setStatusMessage({
                type: "error",
                text: "Password must be at least 8 characters long.",
            });
            return;
        }

        try {
            const result = await updatePassword(userId, formData.newPassword);
            if (result.message === "Password updated successfully.") {
                topRef.current.scrollIntoView({ behavior: "smooth" });
                dispatch(setSuccess("Password updated successfully!"));
                setStatusMessage({
                    type: "success",
                    text: "Password updated successfully!",
                });
                resetForm();
                setTimeout(
                    () => navigate(routePaths.dashboard),
                    FEEDBACK_DISPLAY_DURATION
                );
            } else {
                setStatusMessage({
                    type: "error",
                    text: result.message || "Failed to update password.",
                });
            }
        } catch {
            setStatusMessage({
                type: "error",
                text: "An error occurred while updating the password.",
            });
        }
    };

    return (
        <div className="change-password-container">
            <div ref={topRef}></div>
            <p className="h2">Change Password</p>
            {statusMessage && (
                <div className={statusMessage.type} role="alert">
                    {statusMessage.text}
                </div>
            )}
            <p>
                <span>
                    Password must be at least 10 characters long, include a
                    capital letter, a numeric digit, and a special character.
                </span>
            </p>
            <form onSubmit={handlePasswordUpdate}>
                {["newPassword", "confirmNewPassword"].map((field, idx) => (
                    <div key={idx} className="form-group password-group">
                        <label htmlFor={field}>
                            {field === "newPassword"
                                ? "New Password:"
                                : "Confirm New Password:"}
                        </label>
                        <div className="password-input-container">
                            <input
                                type={
                                    showPasswords[field] ? "text" : "password"
                                }
                                id={field}
                                name={field}
                                value={formData[field]}
                                onChange={handleChange}
                                required
                            />
                            <span
                                className="toggle-password"
                                onClick={() => togglePasswordVisibility(field)}
                            >
                                {showPasswords[field] ? (
                                    <FaEyeSlash className="icon" />
                                ) : (
                                    <FaEye className="icon" />
                                )}
                            </span>
                        </div>
                    </div>
                ))}
                {formData.newPassword && (
                    <p
                        className="password-strength"
                        style={{ color: passwordStrength.color }}
                    >
                        Password Strength (10 characters, include a capital
                        letter, a numeric digit, and a special character.):{" "}
                        {passwordStrength.label}
                    </p>
                )}
                <div className="button-group">
                    <button type="submit" className="btn-submit">
                        Update Password
                    </button>
                    <button
                        type="button"
                        className="btn-cancel"
                        onClick={() => navigate(routePaths.dashboard)}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ChangePassword;
