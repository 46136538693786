// src/components/Credits/BuyCredits.js
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { initiatePayment } from "../../utils/api";
import "./BuyCredits.css";

const creditOptions = [
    { credits: 1, price: 55000, description: "Perfect for once off events." },
    {
        credits: 5,
        price: 250000,
        description: "Ideal for growing businesses with multiple events.",
    },
    {
        credits: 10,
        price: 450000,
        description:
            "The ultimate choice for frequent organizers or larger teams.",
    },
];

const formatNumber = (number) => {
    return number
        .toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true,
        })
        .replace(/,/g, " "); // Replaces commas with spaces
};

const BuyCredits = () => {
    const navigate = useNavigate();
    const userId = useSelector((state) => state.user?.id);
    const isLoggedIn = useSelector((state) => Boolean(state.user));
    const [showModal, setShowModal] = useState(false);
    const [isPurchasing, setIsPurchasing] = useState(false);

    const handlePayment = async (credits, price) => {
        if (isPurchasing) return;

        if (!isLoggedIn) {
            setShowModal(true); // Show limited access modal if not logged in
            return;
        }

        setIsPurchasing(true);

        try {
            const response = await initiatePayment({
                user_id: userId,
                amount: price,
                credit_type: "CreditsB",
                credits,
            });

            if (
                !response.redirectUrl ||
                !response.checkoutId ||
                !response.paymentId
            ) {
                throw new Error("Payment initiation failed.");
            }

            window.location.href = response.redirectUrl; // Redirect to payment gateway
        } catch (err) {
            alert("Payment initiation failed. Please try again.");
        } finally {
            setIsPurchasing(false);
        }
    };

    return (
        <div className="buy-credits-container">
            <h2>Buy Your Credits</h2>
            <div className="credit-options">
                {creditOptions.map((option, index) => (
                    <div key={index} className="credit-card">
                        <div className="card-header">
                            <h3>
                                {option.credits} Credit
                                {option.credits > 1 ? "s" : ""}
                            </h3>
                        </div>
                        <div className="card-body">
                            <p>{option.description}</p>
                        </div>
                        <div className="card-footer">
                            <p className="price">
                                R {formatNumber(option.price / 100)}
                            </p>
                            <button
                                className="btn-buy"
                                onClick={() =>
                                    handlePayment(option.credits, option.price)
                                }
                            >
                                Buy Now
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Limited Access</h3>
                        <p>You need an account to purchase credits.</p>
                        <div className="modal-actions">
                            <button
                                onClick={() => navigate("/register")}
                                className="btn-register"
                            >
                                Register
                            </button>
                            <button
                                onClick={() => navigate("/login")}
                                className="btn-login"
                            >
                                Log In
                            </button>
                        </div>
                        <button
                            className="btn-close"
                            onClick={() => setShowModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BuyCredits;
