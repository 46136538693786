// src/components/Common/HeroSection.js
import React from "react";
import "./HeroSection.css";

const HeroSection = () => (
    <section className="hero-section">
        <h1>Welcome to Zapplyst – Event Management Made Simple</h1>
        <h2>Zapp. List. Done.</h2>
        <p>
            Effortlessly manage guest check-ins and event registration with
            Zapplyst. Seamless, fast, and built for success.
        </p>
    </section>
);

export default HeroSection;
