// src/redux/feedbackSlice.js
import { createSlice } from "@reduxjs/toolkit";

const feedbackSlice = createSlice({
    name: "feedback",
    initialState: { success: "", error: "", timestamp: null },
    reducers: {
        setSuccess: (state, action) => {
            state.success = action.payload;
            state.error = "";
            state.timestamp = Date.now(); // Store current timestamp
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.success = "";
            state.timestamp = Date.now(); // Store current timestamp
        },
        clearFeedback: (state) => {
            state.success = "";
            state.error = "";
            state.timestamp = null;
        },
    },
});

export const { setSuccess, setError, clearFeedback } = feedbackSlice.actions;
export default feedbackSlice.reducer;
