import React, { useState, useEffect } from "react";
import { FaAngleDoubleUp } from "react-icons/fa";
import "./ToTopButton.css";

const ToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        let timeout;
        const toggleVisibility = () => {
            clearTimeout(timeout); // Prevent multiple executions
            timeout = setTimeout(() => {
                setIsVisible(window.scrollY > 300);
            }, 100); // Throttle for better performance
        };

        window.addEventListener("scroll", toggleVisibility);
        return () => {
            clearTimeout(timeout);
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        isVisible && (
            <button
                className="to-top-button"
                onClick={scrollToTop}
                aria-label="Scroll to top"
                title="Scroll to top"
            >
                <FaAngleDoubleUp size={"2em"} />
            </button>
        )
    );
};

export default ToTopButton;
