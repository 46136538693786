// src/pages/Contact.js

import React, { useState } from "react";
import { submitContactForm } from "../../utils/api";
import { useForm } from "../../hooks/useForm";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../utils/routes";
import "./Contact.css";

export default function Contact() {
    const { formData, handleChange, resetForm } = useForm({
        name: "",
        email: "",
        message: "",
    });
    const [statusMessage, setStatusMessage] = useState({ type: "", text: "" });
    const [isSending, setIsSending] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatusMessage({ type: "loading", text: "Sending..." });
        setIsSending(true);

        try {
            const data = await submitContactForm(formData);
            if (data.status === "success") {
                setStatusMessage({
                    type: "success",
                    text:
                        data.message ||
                        "Your message has been sent successfully.",
                });
                resetForm();
                navigate(routePaths.dashboard);
            } else {
                setStatusMessage({
                    type: "error",
                    text: data.message || "Failed to send your message.",
                });
            }
        } catch (error) {
            setStatusMessage({
                type: "error",
                text: "Error: Unable to send message.",
            });
            console.error("Contact form submission error:", error);
        } finally {
            setIsSending(false);
        }
    };

    // Clear status message on input change for fresh feedback
    const handleInputChange = (e) => {
        handleChange(e);
        setStatusMessage({ type: "", text: "" });
    };

    return (
        <div className="contact-container">
            <h2>Contact Us</h2>
            {statusMessage && (
                <p className={statusMessage.type}>{statusMessage.text}</p>
            )}
            <form onSubmit={handleSubmit} className="contact-form">
                <label>
                    Name:
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                    />
                </label>
                <label>
                    Email:
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                    />
                </label>
                <label>
                    Message:
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        required
                    />
                </label>
                <button
                    type="submit"
                    disabled={
                        isSending ||
                        !formData.name ||
                        !formData.email ||
                        !formData.message
                    } // Disable if sending or fields are empty
                >
                    {isSending ? "Sending..." : "Send Message"}
                </button>
            </form>
        </div>
    );
}
