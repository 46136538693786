import React from "react";
import "./GettingStarted.css";
import { FaCheck } from "react-icons/fa";

const GettingStarted = () => {
    return (
        <div className="getting-started-container">
            <h1 className="getting-started-header">
                Getting Started with Zapplyst
            </h1>
            <p className="getting-started-intro">
                Welcome to Zapplyst! Follow these simple steps to make the most
                out of your event management experience.
            </p>

            <div className="steps-container">
                <div className="step-card">
                    <h3>Step 1: Create a Master Account</h3>
                    <p>
                        Sign up to create your master account. This account lets
                        you manage your credits, users, and events.
                    </p>
                </div>

                <div className="step-card">
                    <h3>Step 2: Buy Credits</h3>
                    <p>
                        Purchase credits to create events. One credit equals one
                        event, and credits never expire.
                    </p>
                </div>

                <div className="step-card">
                    <h3>Step 3: Add Users</h3>
                    <p>
                        Add admin and agent users to your account. Admins manage
                        events and guests, while agents handle check-ins.
                    </p>
                </div>

                <div className="step-card">
                    <h3>Step 4: Create and Manage Events</h3>
                    <p>
                        Set up events, import/export guest lists, and keep
                        everything organized. Remember, deleting an event is
                        irreversible.
                    </p>
                </div>

                <div className="step-card">
                    <h3>Step 5: Check-In Guests</h3>
                    <p>
                        Use our efficient check-in tools to checkin arrivals,
                        add walk-ins, cancel guests, and view real-time stats.
                    </p>
                </div>
            </div>

            <div className="tips-section">
                <h2>Quick Tips</h2>
                <ul>
                    <li>
                        <FaCheck className="tip-icon" />
                        <span>
                            Assign user roles carefully to streamline
                            operations.
                        </span>
                    </li>
                    <li>
                        <FaCheck className="tip-icon" />
                        <span>
                            Regularly export guest lists to maintain backups.
                        </span>
                    </li>
                    <li>
                        <FaCheck className="tip-icon" />
                        <span>
                            Use check-in stats for data-driven decisions.
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default GettingStarted;
