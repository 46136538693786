// \src\App.js

import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
    Login,
    Events,
    Register,
    Home,
    EmailVerification,
    Dashboard,
    AddUser,
    AddEvent,
    EditUser,
    EditEvent,
    ViewEvent,
    CheckInGuests,
    RootLayout,
    GuestEdit,
    AddGuest,
    Contact,
    ChangePassword,
    NotFound,
    Spinner,
    BuyCredits,
    GettingStarted,
} from "./components";

import { routePaths } from "./utils/routes";
import { useSelector } from "react-redux";
import Pricing from "./components/Pages/Pricing";

const router = createBrowserRouter([
    {
        path: routePaths.home,
        element: <RootLayout />,
        children: [
            { index: true, element: <Home /> },
            { path: routePaths.login, element: <Login /> },
            { path: routePaths.verifyEmail, element: <EmailVerification /> },
            { path: routePaths.register, element: <Register /> },
            { path: routePaths.events, element: <Events /> },
            { path: routePaths.dashboard, element: <Dashboard /> },
            { path: routePaths.newUser, element: <AddUser /> },
            { path: routePaths.buyCredits, element: <BuyCredits /> },
            { path: routePaths.pricing, element: <Pricing /> },
            { path: routePaths.gettingStarted, element: <GettingStarted /> },
            { path: routePaths.newEvent, element: <AddEvent /> },
            { path: routePaths.editUser, element: <EditUser /> },
            { path: routePaths.editEvent, element: <EditEvent /> },
            { path: routePaths.viewEvent, element: <ViewEvent /> },
            { path: routePaths.checkInGuests, element: <CheckInGuests /> },
            { path: routePaths.guestEdit, element: <GuestEdit /> },
            { path: routePaths.addGuest, element: <AddGuest /> },
            { path: routePaths.contact, element: <Contact /> },
            { path: routePaths.changePassword, element: <ChangePassword /> },

            { path: "*", element: <NotFound /> },
        ],
    },
]);

const App = () => {
    const loading = useSelector((state) => state.global.loading); // Global loading state

    return (
        <>
            <Spinner loading={loading} />
            <RouterProvider router={router} />
        </>
    );
};

export default App;
