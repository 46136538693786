import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routePaths } from "../../utils/routes";
import { useDispatch } from "react-redux";
import { fetchUserDetails, updateUserDetails } from "../../utils/api";
import { setError, setSuccess } from "../../redux/feedbackSlice";
import { useForm } from "../../hooks/useForm";
import "./EditUser.css";

const FEEDBACK_DISPLAY_DURATION = 2500;

const EditUser = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const topRef = useRef(null);

    const { formData, handleChange, setFormData } = useForm({
        first_name: "",
        last_name: "",
        email: "",
        role: "Agent",
    });

    const [statusMessage, setStatusMessage] = useState({ type: "", text: "" });

    // Scroll to the top when the component mounts
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        if (!userId) {
            navigate(routePaths.dashboard); // Redirect if no userId
            return;
        }

        const loadUserData = async () => {
            try {
                const data = await fetchUserDetails(userId);
                if (data) setFormData(data);
            } catch {
                dispatch(setError("Failed to load user data."));
            }
        };
        loadUserData();
    }, [userId, dispatch, setFormData, navigate]);

    const handleUserUpdate = async (e) => {
        e.preventDefault();
        try {
            const result = await updateUserDetails({
                ...formData,
                user_id: userId,
            });
            if (result.message === "User updated successfully.") {
                topRef.current.scrollIntoView({ behavior: "smooth" });
                dispatch(setSuccess("User updated successfully!"));
                setStatusMessage({
                    type: "success",
                    text: "User updated successfully!",
                });
                setTimeout(
                    () => navigate(routePaths.dashboard),
                    FEEDBACK_DISPLAY_DURATION
                );
            } else {
                setStatusMessage({
                    type: "error",
                    text: result.message || "Failed to update user.",
                });
            }
        } catch {
            setStatusMessage({
                type: "error",
                text: "An error occurred while updating the user.",
            });
        }
    };

    return (
        <div className="edit-user-container">
            <div ref={topRef}></div>
            <h1>Edit User</h1>
            {statusMessage && (
                <div className={statusMessage.type} role="alert">
                    {statusMessage.text}
                </div>
            )}

            <form onSubmit={handleUserUpdate}>
                <div className="form-group">
                    <label htmlFor="first_name">First Name:</label>
                    <input
                        type="text"
                        id="first_name"
                        name="first_name"
                        autoComplete="on"
                        value={formData.first_name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="last_name">Last Name:</label>
                    <input
                        type="text"
                        id="last_name"
                        name="last_name"
                        autoComplete="on"
                        value={formData.last_name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        autoComplete="on"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="role">User Role:</label>
                    <select
                        name="role"
                        id="role"
                        value={formData.role}
                        onChange={handleChange}
                    >
                        <option value="Agent">Agent</option>
                        <option value="Admin">Admin</option>
                    </select>
                </div>
                <div className="button-group">
                    <button type="submit" className="btn-submit">
                        Update User Details
                    </button>
                    <button
                        type="button"
                        className="btn-cancel"
                        onClick={() => navigate(routePaths.dashboard)}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditUser;
