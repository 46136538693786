// src/utils/routes.js
export const routePaths = {
    home: "/",
    login: "/login",
    verifyEmail: "/verify-email/:token",
    register: "/register",
    events: "/events",
    dashboard: "/dashboard",
    newUser: "/dashboard/new-user",
    newEvent: "/dashboard/new-event",
    editUser: "/dashboard/edit-user/:userId",
    editEvent: "/dashboard/edit-event/:eventId",
    viewEvent: "/dashboard/view-event/:eventId",
    checkInGuests: "/dashboard/checkin-guests/:eventId",
    guestEdit: "/dashboard/guest_edit/:guestId",
    addGuest: "/dashboard/add-guest/:eventId",
    contact: "/contact",
    buyCredits: "/dashboard/buy-credits",
    pricing: "/pricing",
    gettingStarted: "/starting",
    changePassword: "/dashboard/change-password/:userId",
};
