// src/components/User/Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../utils/api";
import { setError, setSuccess } from "../../redux/feedbackSlice";
import { setUser } from "../../redux/userSlice";
import { useForm } from "../../hooks/useForm";
import { routePaths } from "../../utils/routes";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./Login.css";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { error, success } = useSelector((state) => state.feedback || {});
    const { formData, handleChange } = useForm({ email: "", password: "" });
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // New state for password visibility

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.email || !formData.password) {
            dispatch(setError("Email and password are required."));
            return;
        }

        setLoading(true);
        try {
            const data = await loginUser(formData);

            if (
                data.status === "success" ||
                data.message === "Login successful"
            ) {
                dispatch(
                    setUser({
                        id: data.user_id,
                        firstName: data.first_name,
                        lastName: data.last_name,
                        email: data.email,
                        username: data.username,
                        role: data.role,
                        parentUser: data.parent_user_id,
                    })
                );
                localStorage.setItem("user_id", data.user_id);
                localStorage.setItem(
                    "parent_user_id",
                    JSON.stringify(data.parent_user_id)
                );

                localStorage.setItem("login_timestamp", Date.now());

                dispatch(setSuccess("Login successful!"));
                navigate(routePaths.dashboard);
            } else {
                dispatch(setError(data.message || "Failed to log in."));
            }
        } catch (err) {
            dispatch(setError("Error logging in. Please try again."));
            console.error("Login error:", err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-container">
            <h2>Login</h2>
            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                <div className="password-container">
                    <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        autoComplete="current-password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                    <span
                        className="toggle-password"
                        onClick={() => setShowPassword((prev) => !prev)}
                        aria-label={
                            showPassword ? "Hide password" : "Show password"
                        }
                    >
                        {showPassword ? (
                            <FaEyeSlash className="icon" />
                        ) : (
                            <FaEye className="icon" />
                        )}
                    </span>
                </div>
                <button className="btn-submit" type="submit" disabled={loading}>
                    {loading ? "Logging in..." : "Login"}
                </button>
            </form>
            <p>
                Need an account? <a href="/register">Register here!</a>
            </p>
        </div>
    );
};

export default Login;
