// src/utils/helpers.js

export const formatDate = (dateStr, locale = "en-ZA") => {
    if (!dateStr) return "Invalid date";

    // If `dateStr` is already a Date object, no need to modify it further
    let standardizedDateStr;
    if (dateStr instanceof Date) {
        standardizedDateStr = dateStr;
    } else if (typeof dateStr === "string") {
        // Convert `YYYY-MM-DD HH:MM:SS` to `YYYY-MM-DDTHH:MM:SS` if necessary
        standardizedDateStr = dateStr.includes(" ")
            ? dateStr.replace(" ", "T")
            : dateStr;
    } else {
        console.error("Unsupported date format:", dateStr);
        return "Invalid date";
    }

    // Create a Date object and validate it
    const dateObj =
        standardizedDateStr instanceof Date
            ? standardizedDateStr
            : new Date(standardizedDateStr);
    if (isNaN(dateObj.getTime())) return "Invalid date";

    // Format the date for the specified locale
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return dateObj.toLocaleDateString(locale, options);
};

export const toProperCase = (text) => {
    if (!text) return ""; // Return an empty string if text is undefined or null
    return text
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};
