// src/Event/Event.js
import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { fetchUserEvents } from "../../utils/api";

const MESSAGE_TIMEOUT = 5000;

const Events = () => {
    const user_id = useSelector((state) => state.user.user_id);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const clearMessageAfterDelay = useCallback(() => {
        const timer = setTimeout(() => setError(null), MESSAGE_TIMEOUT);
        return () => clearTimeout(timer);
    }, []);

    const fetchEvents = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetchUserEvents(user_id);

            if (response && response.events) {
                setEvents(response.events);
            } else {
                setError("No events found.");
            }
        } catch (err) {
            setError("Failed to fetch events. Please check your connection.");
        } finally {
            setLoading(false);
        }
    }, [user_id]);

    useEffect(() => {
        if (!user_id) {
            setError("User ID is missing");
            setLoading(false);
        } else {
            fetchEvents();
        }
    }, [user_id, fetchEvents]);

    useEffect(() => {
        if (error) clearMessageAfterDelay();
    }, [error, clearMessageAfterDelay]);

    if (loading) return <p>Loading events...</p>;
    if (error) return <p className="error">{error}</p>;

    return (
        <div>
            <h1>Events</h1>
            {events.length > 0 ? (
                <ul aria-label="Events list">
                    {events.map((event) => (
                        <li
                            key={event.id}
                            aria-label={`Event ${event.event_name}`}
                        >
                            <h2>{event.event_name}</h2>
                            <p>{event.event_date}</p>
                            <p>
                                Custom Fields: {event.cust1}, {event.cust2},{" "}
                                {event.cust3}, {event.cust4}
                            </p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p className="no-events">No events available</p>
            )}
        </div>
    );
};

export default Events;
