import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchCustomLabels } from "../../utils/api";
import {
    FaUserCheck,
    FaUserTimes,
    FaUserSlash,
    FaUserEdit,
} from "react-icons/fa";
import "./GuestPanel.css";

const MESSAGE_TIMEOUT = 5000;

const GuestPanel = ({
    isOpen,
    guest,
    onClose,
    onAction,
    onActionComplete,
    eventId,
}) => {
    const [message, setMessage] = useState(null);
    const [messageType, setMessageType] = useState(""); // Track success or error
    const [checked, setChecked] = useState(0);
    const [checkinGuests, setCheckinGuests] = useState(0);
    const [totalGuests, setTotalGuests] = useState(0);
    const [checkinDate, setCheckinDate] = useState(null);
    const [customLabels, setCustomLabels] = useState({});

    const navigate = useNavigate();
    const userRole = useSelector((state) => state.user?.role?.toLowerCase());
    const userId = useSelector((state) => state.user?.id);

    useEffect(() => {
        const fetchLabels = async () => {
            try {
                const [labelsResponse] = await Promise.all([
                    fetchCustomLabels(eventId, userId),
                ]);

                if (labelsResponse.status === "success") {
                    setCustomLabels({
                        cust1: labelsResponse.event.cust1 || "",
                        cust2: labelsResponse.event.cust2 || "",
                        cust3: labelsResponse.event.cust3 || "",
                        cust4: labelsResponse.event.cust4 || "",
                    });
                } else {
                    setMessageType("error");
                    setMessage(
                        labelsResponse?.message ||
                            "Failed to load event labels."
                    );
                }
            } catch (err) {
                setMessageType("error");
                setMessage(
                    err?.message ||
                        "Error fetching custom labels. Please try again."
                );
                console.error("Fetch labels error:", err);
            }
        };

        if (isOpen) fetchLabels();
    }, [eventId, userId, isOpen]);

    // Sync guest details to panel state
    useEffect(() => {
        if (guest) {
            setChecked(guest.checked || 0);
            setCheckinGuests(guest.checkin_guests || 0);
            setTotalGuests(guest.total_guests || 0);
            setCheckinDate(guest.checkin_date || null);
        }
    }, [guest]);

    // Clear message after timeout
    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => setMessage(null), MESSAGE_TIMEOUT);
            return () => clearTimeout(timer);
        }
    }, [message]);

    if (!isOpen || !guest) return null;

    const checkinTime = checked ? moment(checkinDate).format("HH:mm") : null;
    const checkinFormattedDate = checked
        ? moment(checkinDate).format("Do MMM YYYY")
        : null;

    const handleAction = async (action, guestId) => {
        try {
            const response = await onAction(action, guestId);

            if (response?.status === "success") {
                setMessageType("success");
                setMessage(
                    response.message || "Action completed successfully."
                );

                const currentDate = new Date();

                // Update panel state based on action
                switch (action) {
                    case "checkin":
                        setChecked(1);
                        setCheckinGuests((prev) => prev + 1);
                        setCheckinDate(currentDate);
                        break;
                    case "checkinAll":
                        setChecked(1);
                        setCheckinGuests(totalGuests);
                        setCheckinDate(currentDate);
                        break;
                    case "checkout":
                        setCheckinGuests((prev) => Math.max(prev - 1, 0));
                        if (checkinGuests <= 1) {
                            setChecked(0);
                            setCheckinDate(null);
                        }
                        break;
                    case "cancel":
                        setChecked(0);
                        setCheckinGuests(0);
                        setTotalGuests(0);
                        setCheckinDate(null);
                        break;
                    default:
                        break;
                }

                // Notify parent to refresh data
                if (onActionComplete) onActionComplete();
            } else {
                setMessageType("error");
                setMessage(response?.message || "Failed to perform action.");
            }
        } catch (error) {
            setMessageType("error");
            setMessage("Error performing action. Please try again.");
        }
    };

    const handleEditClick = () => {
        navigate(`/dashboard/guest_edit/${guest.id}`, { state: { eventId } });
    };

    return (
        <div className="guest-panel">
            <div className="guest-panel-header">
                <h3>
                    {guest.first_name} {guest.last_name}
                </h3>
                <button className="close-btn mdl-close" onClick={onClose}>
                    ×
                </button>
            </div>

            {message && (
                <div
                    className={`message ${
                        messageType === "success" ? "success" : "error"
                    }`}
                >
                    {message}
                </div>
            )}

            <div className="guest-panel-body">
                {guest.alert_message && (
                    <div className="alert alert-warning">
                        {guest.alert_message}
                    </div>
                )}
                {guest.email && (
                    <p>
                        <strong>Email:</strong> {guest.email}
                    </p>
                )}
                {guest.phone && (
                    <p>
                        <strong>Phone:</strong> {guest.phone}
                    </p>
                )}
                {guest.company && (
                    <p>
                        <strong>Company:</strong> {guest.company}
                    </p>
                )}
                {totalGuests > 0 && (
                    <p>
                        <strong>Total Guests:</strong> {totalGuests}
                    </p>
                )}
                {checkinGuests > 0 && (
                    <p>
                        <strong>Check-In Guests:</strong> {checkinGuests}
                    </p>
                )}
                {customLabels.cust1 && guest.cust1 && (
                    <p>
                        <strong>{customLabels.cust1}:</strong> {guest.cust1}
                    </p>
                )}
                {customLabels.cust2 && guest.cust2 && (
                    <p>
                        <strong>{customLabels.cust2}:</strong> {guest.cust2}
                    </p>
                )}
                {customLabels.cust3 && guest.cust3 && (
                    <p>
                        <strong>{customLabels.cust3}:</strong> {guest.cust3}
                    </p>
                )}
                {customLabels.cust4 && guest.cust4 && (
                    <p>
                        <strong>{customLabels.cust4}:</strong> {guest.cust4}
                    </p>
                )}
                {checked === 1 && (
                    <p className="badge bg-success">
                        Checked in at {checkinTime} on {checkinFormattedDate}
                    </p>
                )}
                {!checked && <p className="badge bg-warning">Not checked in</p>}
            </div>

            <div className="guest-panel-actions">
                <button
                    className="mdl-success"
                    onClick={() => handleAction("checkin", guest.id)}
                >
                    <FaUserCheck /> Check-In
                </button>
                {totalGuests > 1 && (
                    <button
                        className="mdl-success"
                        onClick={() => handleAction("checkinAll", guest.id)}
                        disabled={
                            checked === 1 && checkinGuests === totalGuests
                        }
                    >
                        <FaUserCheck /> Check-In All
                    </button>
                )}
                <button
                    className="mdl-warning"
                    onClick={() => handleAction("checkout", guest.id)}
                    disabled={!checked || checkinGuests === 0}
                >
                    <FaUserTimes /> Check-Out
                </button>
                <button
                    className="mdl-danger"
                    onClick={() => handleAction("cancel", guest.id)}
                >
                    <FaUserSlash /> Cancel
                </button>
                {userRole === "admin" && (
                    <button className="mdl-edit" onClick={handleEditClick}>
                        <FaUserEdit /> Edit
                    </button>
                )}
            </div>
        </div>
    );
};

GuestPanel.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    guest: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onAction: PropTypes.func.isRequired,
    onActionComplete: PropTypes.func,
    eventId: PropTypes.string.isRequired,
};

export default GuestPanel;
