import React, { useState, useEffect, useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
    FaHome,
    FaEnvelope,
    FaBars,
    FaTimes,
    FaSignOutAlt,
    FaFlipboard,
    FaSignInAlt,
    FaUserPlus,
    FaStore,
    FaCompass,
} from "react-icons/fa";
import { clearUser } from "../../redux/userSlice";
import { setSuccess } from "../../redux/feedbackSlice";
import styles from "./MainNavigation.module.css";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../assets/images/Burgandy1.png";

export default function MainNavigation() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userLoggedIn = Boolean(useSelector((state) => state.user));

    const handleLogout = useCallback(() => {
        dispatch(clearUser());
        dispatch(setSuccess("Logout successful!"));
        localStorage.removeItem("user");
        localStorage.removeItem("login_timestamp");
        navigate("/login");
    }, [dispatch, navigate]);

    const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
    useEffect(() => {
        const checkSession = () => {
            const loginTimestamp = localStorage.getItem("login_timestamp");
            if (loginTimestamp) {
                const elapsedTime = Date.now() - parseInt(loginTimestamp, 10);
                const maxSessionTime = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

                if (elapsedTime >= maxSessionTime) {
                    handleLogout();
                } else {
                    setTimeout(handleLogout, maxSessionTime - elapsedTime);
                }
            }
        };

        checkSession();
    }, [handleLogout]);

    useEffect(() => {
        const handleScroll = () => setIsScrolled(window.scrollY > 10);
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <header
            className={`${styles.header} ${isScrolled ? styles.scrolled : ""}`}
        >
            <nav role="navigation" aria-label="Main navigation">
                <div className={styles.navContainer}>
                    <div className={styles.logoContainer}>
                        <img
                            src={logo}
                            alt="Zapplyst Logo"
                            className={styles.logo}
                        />
                    </div>

                    <button
                        className={styles.mobileMenuToggle}
                        aria-label="Toggle menu"
                        onClick={toggleMobileMenu}
                    >
                        {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
                    </button>

                    <ul
                        className={`${styles.list} ${
                            isMobileMenuOpen ? styles.mobileMenuOpen : ""
                        }`}
                    >
                        <li>
                            <NavLink
                                to="/"
                                className={({ isActive }) =>
                                    `${styles.navItem} ${
                                        isActive ? styles.active : ""
                                    }`
                                }
                                end
                                aria-label="Go to Home page"
                            >
                                <FaHome className={styles.icon} />
                                <span>Home</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/starting"
                                className={({ isActive }) =>
                                    `${styles.navItem} ${
                                        isActive ? styles.active : ""
                                    }`
                                }
                                aria-label="Go to Getting Started page"
                            >
                                {" "}
                                <FaCompass className={styles.icon} />
                                Getting Started
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/pricing"
                                className={({ isActive }) =>
                                    `${styles.navItem} ${
                                        isActive ? styles.active : ""
                                    }`
                                }
                                aria-label="Go to Pricing page"
                            >
                                {" "}
                                <FaStore className={styles.icon} />
                                Pricing
                            </NavLink>
                        </li>

                        <li>
                            <NavLink
                                to="/contact"
                                className={({ isActive }) =>
                                    `${styles.navItem} ${
                                        isActive ? styles.active : ""
                                    }`
                                }
                                aria-label="Go to Contact page"
                            >
                                <FaEnvelope className={styles.icon} />
                                <span>Contact Us</span>
                            </NavLink>
                        </li>
                        {userLoggedIn ? (
                            <>
                                <li>
                                    <NavLink
                                        to="/dashboard"
                                        className={({ isActive }) =>
                                            `${styles.navItem} ${
                                                isActive ? styles.active : ""
                                            }`
                                        }
                                        aria-label="Go to Dashboard"
                                    >
                                        <FaFlipboard className={styles.icon} />
                                        <span>Dashboard</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <span
                                        onClick={handleLogout}
                                        className={`${styles.navItem} ${styles.logout}`}
                                        aria-label="Log out"
                                    >
                                        <FaSignOutAlt className={styles.icon} />
                                        <span>Logout</span>
                                    </span>
                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    <NavLink
                                        to="/login"
                                        className={({ isActive }) =>
                                            `${styles.navItem} ${
                                                isActive ? styles.active : ""
                                            }`
                                        }
                                        aria-label="Go to Login"
                                    >
                                        <FaSignInAlt className={styles.icon} />
                                        <span>Login</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/register"
                                        className={({ isActive }) =>
                                            `${styles.navItem} ${
                                                isActive ? styles.active : ""
                                            }`
                                        }
                                        aria-label="Go to Register"
                                    >
                                        <FaUserPlus className={styles.icon} />
                                        <span>Register</span>
                                    </NavLink>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </nav>
        </header>
    );
}
