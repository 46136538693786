// src/components/Common/CallToActionSection.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../utils/routes";
import "./CallToActionSection.css";

const CallToActionSection = () => {
    const navigate = useNavigate();

    return (
        <section className="cta-section">
            <h2>Get Started Today!</h2>
            <p>
                Join event organizers worldwide who trust Zapplyst for flawless
                event execution. Experience streamlined guest management like
                never before.
            </p>
            <button
                className="cta-button"
                onClick={() => navigate(routePaths.register)}
            >
                Sign Up Now
            </button>
            <button
                className="cta-prices"
                onClick={() => navigate(routePaths.pricing)}
            >
                View Pricing
            </button>
        </section>
    );
};

export default CallToActionSection;
